import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
export default function Footer() {
  const [termAndPolicyOpen, setTermAndPolicyOpen] = useState(false);
  const [termOpen, setTermOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleTermAndPolicyModal = () => {
    setTermAndPolicyOpen(!termAndPolicyOpen);
  };

  const toggleTermModal = () => {
    setTermOpen(!termOpen);
  };
  return (
    <div>
      <footer className="text-center p-8">
        <p className="text-[#CADCE6] font-bold text-sm sm:text-base mt-4">
          {t("openingHour")}
        </p>
        <p className="text-[#CADCE6] text-xs sm:text-sm mt-4">
          © 2024 8bot. {t("footer")}
        </p>
        <p className="mt-2">
          <a href="#" className="text-[#CADCE6] mx-2">
            <FontAwesomeIcon icon={faFacebook} className="w-6 h-6" />
          </a>
          <a href="#" className="text-[#CADCE6] mx-2">
            <FontAwesomeIcon icon={faInstagram} className="w-6 h-6" />
          </a>
        </p>
        <a href="mailto:eightbot88@hotmail.com" className="text-[#CADCE6] mt-2">
          eightbot88@hotmail.com
        </a>

        <div className="text-[#CADCE6] text-xs sm:text-sm cursor-pointer mt-4">
          <span className="underline" onClick={toggleTermAndPolicyModal}>
            {t("privacy")}
          </span>{" "}
          {t("and")}{" "}
          <span className="underline" onClick={toggleTermModal}>
            {t("term")}
          </span>
        </div>
      </footer>

      {termAndPolicyOpen && (
        <div className="overlay" onClick={toggleTermAndPolicyModal}>
          <div className="modal mx-4">
            {/* Left Section */}

            <h1 className="text-2xl font-bold mb-4">
              {t("privacyPolicy.title")}
            </h1>

            <p className="mb-4">
              1. {t("privacyPolicy.title_1")}
              <br />
              {t("privacyPolicy.content_1")}
            </p>
            <p className="mb-4">
              2. {t("privacyPolicy.title_2")}
              <br />
              {t("privacyPolicy.content_2")}
            </p>
            <p className="mb-4">
              3. {t("privacyPolicy.title_3")}
              <br />
              {t("privacyPolicy.content_3")}
            </p>
            <p className="mb-4">
              4. {t("privacyPolicy.title_4")}
              <br />
              {t("privacyPolicy.content_4")}
            </p>
            <p className="mb-4">
              5. {t("privacyPolicy.title_5")}
              <br />
              {t("privacyPolicy.content_5")}
            </p>
          </div>
          <div></div>
        </div>
      )}

      {termOpen && (
        <div className="overlay" onClick={toggleTermModal}>
          <div className="modal mx-4">
            {/* Left Section */}
            <h1 className="text-2xl font-bold mb-4">
              1. {t("termsOfService.1.title")}
            </h1>

            <p className="mb-4">
              1.1 {t("termsOfService.1.1.1")}
              <br /> <br />
              1.2 {t("termsOfService.1.1.2")}
              <br /> <br />
              1.3 {t("termsOfService.1.1.3")}
              <br /> <br />
              1.4 {t("termsOfService.1.1.4")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              2. {t("termsOfService.2.title")}
            </h1>

            <p className="mb-4">
              2.1 {t("termsOfService.2.2.1")}
              <br /> <br />
              2.2 {t("termsOfService.2.2.2")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              3. {t("termsOfService.3.title")}
            </h1>

            <p className="mb-4">
              3.1 {t("termsOfService.3.3.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              4. {t("termsOfService.4.title")}
            </h1>

            <p className="mb-4">
              4.1 {t("termsOfService.4.4.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              5. {t("termsOfService.5.title")}
            </h1>

            <p className="mb-4">
              5.1 {t("termsOfService.5.5.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              6. {t("termsOfService.6.title")}
            </h1>

            <p className="mb-4">
              6.1 {t("termsOfService.6.6.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              7. {t("termsOfService.7.title")}
            </h1>

            <p className="mb-4">
              7.1 {t("termsOfService.7.7.1")}
              <br /> <br />
              7.2 {t("termsOfService.7.7.2")}
            </p>
          </div>
          <div></div>
        </div>
      )}
    </div>
  );
}
