import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  FpxBankElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js"; // Import loadStripe function
import "./CheckoutPage.css"; // Import your custom CSS
import StatusMessages, { useMessages } from "./StatusMessages";
import { useTranslation } from "react-i18next";

const FpxForm = ({ price }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [messages, addMessage] = useMessages();
  const [loadingMsg, setLoadingMsg] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState(""); // State to hold the email input value
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const [termAndPolicyOpen, setTermAndPolicyOpen] = useState(false);
  const [termOpen, setTermOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handlePolicyCheck = () => {
    setIsPolicyAccepted(!isPolicyAccepted);
  };

  const toggleTermAndPolicyModal = () => {
    setTermAndPolicyOpen(!termAndPolicyOpen);
  };

  const toggleTermModal = () => {
    setTermOpen(!termOpen);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (e) => {
    setErrorMessage("");
    setLoadingMsg("");
    e.preventDefault();

    if (isPolicyAccepted) {
      try {
        const response = await fetch("https://8bot-api.vercel.app/api/policy", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });
        if (response.ok) {
        } else {
        }
      } catch (error) {
        console.error("Error accepting policy:", error);
      }
      if (!stripe || !elements) {
        addMessage("Stripe.js has not yet loaded.");
        return;
      }

      let { error: backendError, clientSecret } = await fetch(
        "https://8bot-api.vercel.app/create-payment-intent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentMethodType: "fpx",
            currency: "myr",
            amount: price * 100,
          }),
        }
      ).then((r) => r.json());

      if (backendError) {
        addMessage(backendError.message);
        return;
      }
      setLoadingMsg(t("paymentL"));

      let { error: stripeError, paymentIntent } =
        await stripe.confirmFpxPayment(clientSecret, {
          payment_method: {
            fpx: elements.getElement(FpxBankElement),
          },
          return_url: `${window.location.origin}/thankyou?return=true&amount=${price}&method=fpx&email=${email}`,
        });

      if (stripeError) {
        // Show error to your customer (e.g., insufficient funds)
        setErrorMessage(t("paymentE"));
        setTimeout(() => {
          addMessage("");
        }, 3000);
        return;
      } else {
        try {
          const response = await fetch(
            "https://8bot-api.vercel.app/api/payment",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                paymentIntentId: paymentIntent.id,
                amount: paymentIntent.amount,
                email: email,
                productName: localStorage.getItem("productName"),
              }),
            }
          );
          const data = await response.json();
        } catch (error) {
          console.error("Error posting payment details to server:", error);
        }
      }

      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.
      addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
    } else {
      setShowErrorMessage("Please accept the policy before submitting");
      setTimeout(() => {
        setShowErrorMessage("");
      }, 2000);
    }
  };

  const fpxOptions = {
    style: {
      base: {
        color: "#32325d", // Change text color
        backgroundColor: "#ffffff", // Change background color to white
        padding: "4px", // Add padding of 10px
        borderRadius: "5px", // Add border radius of 5px
        "::placeholder": {
          color: "#aab7c4", // Change placeholder color
        },
      },
      invalid: {
        color: "#fa755a", // Change invalid text color
      },
    },
  };

  const { t, i18n } = useTranslation();

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-[#CADCE6]"
          >
            {t("email")}
          </label>
          <input
            type="email"
            id="email"
            className="mt-1 block w-full px-3 py-1 bg-[#0F1C2D] border border-[#CADCE6] rounded-md text-base text-[#CADCE6]"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>
        <p className="block text-sm font-medium text-[#CADCE6] mb-1">
          {" "}
          {t("chooseBank")}
        </p>
        <FpxBankElement
          options={{ accountHolderType: "individual", ...fpxOptions }}
        />

        <div className="flex items-center mt-5">
          <input
            type="checkbox"
            id="policyCheckbox"
            className="mr-2"
            checked={isPolicyAccepted}
            onChange={() => setIsPolicyAccepted(!isPolicyAccepted)}
          />
          <label htmlFor="policyCheckbox" className="text-sm text-[#CADCE6]">
            {t("agree")}{" "}
            <span
              className="underline cursor-pointer"
              onClick={toggleTermAndPolicyModal}
            >
              {t("privacy")}
            </span>{" "}
            {t("and")}{" "}
            <span
              className="underline cursor-pointer"
              onClick={toggleTermModal}
            >
              {t("term")}
            </span>
          </label>
        </div>
        <button
          type="submit"
          className="mt-7 w-full flex justify-center py-3 px-4 align-items-center border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 focus:outline-none "
        >
          {t("pay_btn")}
        </button>
      </form>

      {!errorMessage && <div>{loadingMsg}</div>}
      {errorMessage && <div className="text-red-600">{errorMessage}</div>}
      {showErrorMessage && (
        <div className="text-red-500 text-sm mb-4">{t("pay_error_msg")}</div>
      )}
      {termAndPolicyOpen && (
        <div className="overlay" onClick={toggleTermAndPolicyModal}>
          <div className="modal mx-4">
            {/* Left Section */}

            <h1 className="text-2xl font-bold mb-4">
              {t("privacyPolicy.title")}
            </h1>

            <p className="mb-4">
              1. {t("privacyPolicy.title_1")}
              <br />
              {t("privacyPolicy.content_1")}
            </p>
            <p className="mb-4">
              2. {t("privacyPolicy.title_2")}
              <br />
              {t("privacyPolicy.content_2")}
            </p>
            <p className="mb-4">
              3. {t("privacyPolicy.title_3")}
              <br />
              {t("privacyPolicy.content_3")}
            </p>
            <p className="mb-4">
              4. {t("privacyPolicy.title_4")}
              <br />
              {t("privacyPolicy.content_4")}
            </p>
            <p className="mb-4">
              5. {t("privacyPolicy.title_5")}
              <br />
              {t("privacyPolicy.content_5")}
            </p>
          </div>
          <div></div>
        </div>
      )}

      {termOpen && (
        <div className="overlay" onClick={toggleTermModal}>
          <div className="modal mx-4">
            {/* Left Section */}
            <h1 className="text-2xl font-bold mb-4">
              1. {t("termsOfService.1.title")}
            </h1>

            <p className="mb-4">
              1.1 {t("termsOfService.1.1.1")}
              <br /> <br />
              1.2 {t("termsOfService.1.1.2")}
              <br /> <br />
              1.3 {t("termsOfService.1.1.3")}
              <br /> <br />
              1.4 {t("termsOfService.1.1.4")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              2. {t("termsOfService.2.title")}
            </h1>

            <p className="mb-4">
              2.1 {t("termsOfService.2.2.1")}
              <br /> <br />
              2.2 {t("termsOfService.2.2.2")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              3. {t("termsOfService.3.title")}
            </h1>

            <p className="mb-4">
              3.1 {t("termsOfService.3.3.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              4. {t("termsOfService.4.title")}
            </h1>

            <p className="mb-4">
              4.1 {t("termsOfService.4.4.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              5. {t("termsOfService.5.title")}
            </h1>

            <p className="mb-4">
              5.1 {t("termsOfService.5.5.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              6. {t("termsOfService.6.title")}
            </h1>

            <p className="mb-4">
              6.1 {t("termsOfService.6.6.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              7. {t("termsOfService.7.title")}
            </h1>

            <p className="mb-4">
              7.1 {t("termsOfService.7.7.1")}
              <br /> <br />
              7.2 {t("termsOfService.7.7.2")}
            </p>
          </div>
          <div></div>
        </div>
      )}
    </>
  );
};

// Component for displaying results after returning from
// bancontact redirect flow.
const FpxReturn = () => {
  const stripe = useStripe();
  const [messages, addMessage] = useMessages();

  const query = new URLSearchParams(useLocation().search);
  const clientSecret = query.get("payment_intent_client_secret");

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const fetchPaymentIntent = async () => {
      const { error, paymentIntent } = await stripe.retrievePaymentIntent(
        clientSecret
      );
      if (error) {
        addMessage(error.message);
      }
      addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
    };
    fetchPaymentIntent();
  }, [clientSecret, stripe, addMessage]);

  return (
    <>
      <h1>FPX Return</h1>
      <StatusMessages messages={messages} />
    </>
  );
};

const CheckoutFPX = ({ stripePromise, productName, price }) => {
  const query = new URLSearchParams(useLocation().search);
  useEffect(() => {
    if (productName) {
      localStorage.setItem("productName", productName);
    }
  }, [productName]);
  if (query.get("return")) {
    return <FpxReturn />;
  } else {
    return (
      <Elements stripe={stripePromise}>
        <FpxForm price={price} />
      </Elements>
    );
  }
};

export default CheckoutFPX;
