import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm"; // Import your CheckoutForm
import "./CheckoutPage.css"; // Import your custom CSS
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlassChart } from "@fortawesome/free-solid-svg-icons";
import { useLocation, Link } from "react-router-dom";
import CheckoutFPX from "./CheckoutFPX";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CheckoutPage = () => {
  const location = useLocation();
  const { productDetails } = location.state;
  const [isVisaMaster, setIsVisaMaster] = useState(false);
  const [isOnlineBanking, setIsOnlineBanking] = useState(false);

  const closeOnlineBankingNVisaMaster = () => {
    setIsVisaMaster(false);
    setIsOnlineBanking(false);
  };

  const { t, i18n } = useTranslation();
  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      // Set a default language if none is stored
      i18n.changeLanguage("en");
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="h-screen bg-[#0F1C2D] text-white md:px-0 px-4 md:py-20 py-10">
      <Helmet>
        <title>Checkout - 8bot.tech</title>
        <link rel="canonical" href="https://8bot.tech/checkout" />
      </Helmet>
      <div className="flex flex-col md:flex-row justify-between max-w-6xl mx-auto gap-10 h-full">
        {/* Left: Checkout Form */}
        <div className="md:w-1/2 space-y-6">
          {isVisaMaster || isOnlineBanking ? (
            <div className="back-icon" onClick={closeOnlineBankingNVisaMaster}>
              <FontAwesomeIcon icon={faArrowLeft} size="lg" />
            </div>
          ) : (
            <Link to="/" className="back-icon">
              <FontAwesomeIcon icon={faArrowLeft} size="lg" />
            </Link>
          )}

          <h2 className="text-[#E5F4FF] text-3xl sm:text-4xl font-bold text-center mb-6">
            {t("Checkout")}
          </h2>

          {!isVisaMaster && !isOnlineBanking && (
            <div className="flex ">
              <button
                type="submit"
                onClick={() => setIsVisaMaster(true)}
                className="w-full flex justify-center py-3 px-4 align-items-center border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 focus:outline-none"
              >
                <img src="Logo-VisaMaster.png" className="w-10 my-auto" />{" "}
                <span className="my-auto ml-2"> Mastercard / Visa</span>
              </button>
              <button
                type="submit"
                onClick={() => setIsOnlineBanking(true)}
                className="ml-2 w-full flex justify-center py-3 px-4 align-items-center border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 focus:outline-none "
              >
                <img src="Logo-FPX.png" className="w-10" />{" "}
                <span className="my-auto ml-2"> {t("Online")}</span>
              </button>
            </div>
          )}

          {isVisaMaster && (
            <Elements stripe={stripePromise}>
              <CheckoutForm
                productName={productDetails.productName}
                price={productDetails.price}
              />
            </Elements>
          )}

          {isOnlineBanking && (
            <CheckoutFPX
              stripePromise={stripePromise}
              productName={productDetails.productName}
              price={productDetails.price}
            />
          )}
        </div>

        {/* Right: Order Summary */}
        <div className="right-section md:w-1/2 bg-[#E5F4FF] text-[#0F1C2D]  p-6">
          <h3 className="text-3xl sm:text-4xl font-bold mb-4 text-center">
            {t("summary")}
          </h3>
          <div className="product-details flex  items-center my-2">
            {productDetails.type != 2 ? (
              <div className={`w-2/12`}>
                <img src="data_analysis.png" className="w-full" />
              </div>
            ) : (
              <div className={`w-2/12 `}>
                <img src="chat_room2.PNG" className="w-full" />
              </div>
            )}

            <div className="w-10/12 ml-4">
              <p className="product-name"> {t(productDetails.productName)}</p>
              <div className="flex justify-between">
                <p className="product-quantity">X {productDetails.quantity}</p>
                <p className="product-price">RM {productDetails.price}</p>
              </div>
            </div>
          </div>
          <p className="total-amount">
            {t("amount")} RM {productDetails.price}
          </p>
          {/* You can add more details or a list of items here */}
        </div>
      </div>
    </section>
  );
};

export default CheckoutPage;
