import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../../UserPool";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-brands-svg-icons";
import { faEye, faEyeDropper } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const cookies = new Cookies();
const LogIn = () => {
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(false);
  const [state, dispatch] = useContext(AuthContext);
  const { email, password } = formValue;
  const [newPassword, setNewPassword] = useState("");
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [userAttr, setUserAttr] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { t, i18n } = useTranslation();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (error) {
      setError(false);
    }
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();

    if (formValue) {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });
      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });
      setIsLoading(true);
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          var token = data.getAccessToken().getJwtToken();
          dispatch({ type: "LOGIN", payload: { token: token } });
          setIsLoading(false);
          if (token) {
            navigate("/");
          }
        },
        onFailure: (err) => {
          console.error("onFailure", err, authDetails);
          if (err.code === "NotAuthorizedException") {
            setIsLoading(false);
            setError(t("invalidpws"));
          } else {
            setIsLoading(false);
            setError(t("invalidpws"));
          }
        },
        newPasswordRequired: (data) => {
          setIsLoading(false);
          setUserAttr(data);
          setCognitoUser(user);
          setIsChangingPassword(true);
        },
      });
    } else {
      setIsLoading(false);
      setError("Please enter email and password.");
    }
  };

  const completePasswordChange = () => {
    setIsLoading(true); // Set loading state to true when password change is initiated
    if (newPassword && confirmPassword && !passwordErrorMessage) {
      cognitoUser.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          onSuccess: (data) => {
            var token = data.getAccessToken().getJwtToken();
            dispatch({ type: "LOGIN", payload: { token: token } });
            setIsChangingPassword(false);
            setError("");
            if (token) {
              navigate("/");
            }
          },
          onFailure: (err) => {
            console.error("Error updating password:", err);
            setError(err.message || "Failed to update password");
          },
        }
      );
    } else if (!newPassword && !confirmPassword && passwordErrorMessage) {
      setIsLoading(false);
      setError(t("invalidpws1"));
    } else if (!newPassword && !confirmPassword) {
      setIsLoading(false);
      setError(t("invalidpws1"));
    }
    setIsLoading(false);
  };
  const toggleForgotPassword = () => {
    setShowForgotPassword(!showForgotPassword);
    setFormValue("");
    setForgotEmail("");
    setError("");
  };
  const toggleChangeForgotPassword = () => {
    setPasswordChangeSuccess(!passwordChangeSuccess);
    setPasswordResetSuccess(!passwordResetSuccess);
    setShowForgotPassword(!showForgotPassword);
    setFormValue("");
    setForgotEmail("");
    setError("");
    setVerificationCode("");
    setNewPassword("");
    setConfirmPassword("");
  };
  const toggleChangePassword = () => {
    setIsChangingPassword(true);
  };

  const handleForgotPassword = () => {
    const user = new CognitoUser({
      Username: forgotEmail,
      Pool: UserPool,
    });
    setIsLoading(true);

    user.forgotPassword({
      onSuccess: (data) => {
        setIsLoading(false);
        setPasswordResetSuccess(!passwordResetSuccess);
        setCognitoUser(user);
        // Show a success message to the user
      },
      onFailure: (err) => {
        if (err.code === "ExpiredCodeException") {
          // Handle expired verification code
          user.resendConfirmationCode((err, result) => {
            if (err) {
              console.error("Error resending verification code:", err);
              setError("Failed to resend verification code. Please try again.");
              setIsLoading(false);
            } else {
              setError(""); // Clear any previous error message
              setIsLoading(false);
              // You can show a message to the user indicating that the code has been resent
            }
          });
        } else {
          setError(t("invalidEmail"));
          console.error("Error sending password reset code:", err);
          // Handle other types of errors
          setIsLoading(false);
        }
      },
    });
  };

  const completeForgotPasswordChange = () => {
    if (verificationCode && newPassword && confirmPassword) {
      setIsLoading(true);
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: (data) => {
          setPasswordChangeSuccess(true);
          setIsLoading(false); // Set loading state to false when password change is completed
        },
        onFailure: (err) => {
          console.error("Error resetting password:", err);
          if (err.code === "CodeMismatchException") {
            setError(t("errorcode1"));
            setIsLoading(false); // Set loading state to false when password change is completed
          } else {
            setError(err.message || "Failed to reset password");
            setIsLoading(false); // Set loading state to false when password change is completed
          }
        },
      });
    } else {
      setIsLoading(false);
      setError(t("errorcode"));
    }
  };

  const checkPasswordRequirements = (e, f) => {
    setError("");
    const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
    const isValidPassword = regex.test(e);
    const passwordsMatch = e === f;
    if (!isValidPassword) {
      setPasswordErrorMessage(t("pwscheck"));
    } else {
      setPasswordErrorMessage("");
      if (f && !passwordsMatch) {
        setPasswordErrorMessage(t("pwsdismatch"));
      } else {
        setPasswordErrorMessage("");
      }
    }
  };

  //check token exist
  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      // Set a default language if none is stored
      i18n.changeLanguage("en");
    }
    let token = cookies.get("token");
    if (token) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Login - 8bot.tech</title>
        <link rel="canonical" href="https://8bot.tech/auth/login" />
      </Helmet>
      <div className="login-container">
        <div className="login-form">
          <div className="text-center mb-[20px]">
            <span className="font-semibold text-3xl tracking-tight text-[#CADCE6]">
              <a href="/">{t("appName")}</a>
            </span>
          </div>
          {!showForgotPassword &&
            !passwordResetSuccess &&
            !passwordChangeSuccess && (
              <>
                {isChangingPassword ? (
                  <div>
                    <h3
                      className="text-xl font-bold text-[#E5F4FF] mb-4 text-center"
                      style={{ fontFamily: "Roboto, Arial, Helvetica Neue" }}
                    >
                      {t("setNewPassword")}
                    </h3>
                    <label
                      htmlFor="Password"
                      className="block text-sm font-medium text-[#CADCE6]"
                    >
                      {t("newPassword")}
                    </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                        checkPasswordRequirements(
                          e.target.value,
                          confirmPassword
                        );
                      }}
                      className="mt-1 block w-full px-3 py-2 bg-[transparent] border border-[#CADCE6] rounded-md text-base text-[#CADCE6] focus:outline-none focus:border-[#1DBF73] h-12"
                    />
                    <div className="mt-4">
                      <label
                        htmlFor="Password"
                        className="block text-sm font-medium text-[#CADCE6]"
                      >
                        {t("confirmPassword")}
                      </label>
                      <input
                        type={showPassword ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          checkPasswordRequirements(
                            newPassword,
                            e.target.value
                          );
                        }}
                        className="mt-1 block w-full px-3 py-2 bg-[transparent] border border-[#CADCE6] rounded-md text-base text-[#CADCE6] focus:outline-none focus:border-[#1DBF73] h-12"
                      />
                    </div>

                    <div className="mt-4 text-center text-sm font-medium text-red-500">
                      {passwordErrorMessage} {error}
                    </div>

                    <div className="flex items-center justify-between mt-6">
                      <button
                        onClick={completePasswordChange}
                        className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1DBF73]"
                        disabled={isLoading} // Disable the button when loading
                      >
                        {isLoading ? t("loading") : t("changePassword")}
                      </button>
                    </div>

                    <a
                      onClick={toggleChangePassword}
                      className="block text-sm font-medium text-[#CADCE6] text-center mt-[20px] cursor-pointer w-full"
                    >
                      {t("backToSignIn")}
                    </a>
                  </div>
                ) : (
                  <form onSubmit={onSubmit}>
                    {/* <div className={error?"bg-red-100 py-2 px-2 text-red-500 text-sm":"hidden"}>          <ErrorMsg errors={errorval} /></div> */}
                    <div className="">
                      <div className="mt-4">
                        <label
                          htmlFor="Email"
                          className="block text-sm font-medium text-[#CADCE6]"
                        >
                          {t("email")}
                        </label>
                      </div>
                      <input
                        type="text"
                        name="email"
                        className="mt-1 block w-full px-3 py-2 bg-[transparent] border border-[#CADCE6] rounded-md text-base text-[#CADCE6] focus:outline-none focus:border-[#1DBF73] h-12"
                        value={email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mt-4">
                      <label
                        htmlFor="Password"
                        className="block text-sm font-medium text-[#CADCE6]"
                      >
                        {t("password")}
                      </label>
                      <div className="relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={password}
                          className="mt-1 block w-full px-3 py-2 bg-[transparent] border border-[#CADCE6] rounded-md text-base text-[#CADCE6] focus:outline-none focus:border-[#1DBF73] h-12 pr-10"
                          onChange={handleInputChange}
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 flex items-center px-3 text-[#CADCE6] focus:outline-none"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <FontAwesomeIcon icon={faEye} size="1x" />
                          ) : (
                            <FontAwesomeIcon icon={faEye} size="1x" />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="mt-4 text-center text-sm font-medium text-red-500">
                      {error}
                    </div>
                    <div className="flex items-center justify-between mt-6">
                      <button
                        type="submit"
                        className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1DBF73]"
                        disabled={isLoading} // Disable the button when loading
                      >
                        {isLoading ? t("loading") : t("signIn")}
                      </button>
                    </div>
                  </form>
                )}
              </>
            )}

          {showForgotPassword &&
            !passwordResetSuccess &&
            !passwordChangeSuccess && (
              <div className="forgot-password-section">
                <img
                  src="../forgot-psw.png"
                  className="w-1/3 mx-auto"
                  alt="Forgot Password"
                />
                <h3
                  className="text-xl font-bold text-[#E5F4FF] mb-4 text-center"
                  style={{ fontFamily: "Roboto, Arial, Helvetica Neue" }}
                >
                  {t("forgotYourPassword")}
                </h3>
                <p className="text-[#CADCE6] mb-4 text-center">
                  {t("forgotPasswordInstructions")}
                </p>
                <input
                  type="email"
                  value={forgotEmail}
                  onChange={(e) => {
                    setForgotEmail(e.target.value);
                    setError("");
                  }}
                  placeholder="Enter your email"
                  className="mt-1 block w-full px-3 py-2 bg-[transparent] border border-[#CADCE6] rounded-md text-base text-[#CADCE6] focus:outline-none focus:border-[#1DBF73] h-12 mb-4"
                />

                <div className="my-4 text-center text-sm font-medium text-red-500">
                  {error}
                </div>
                <div className="text-center">
                  <button
                    onClick={handleForgotPassword}
                    className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1DBF73]"
                    disabled={isLoading}
                  >
                    {isLoading ? t("loading") : t("sendResetInstructions")}
                  </button>
                  <a
                    onClick={toggleForgotPassword}
                    className="block text-sm font-medium text-[#CADCE6] text-center mt-[20px] cursor-pointer w-full"
                  >
                    {t("backToSignIn")}
                  </a>
                </div>
              </div>
            )}

          {passwordResetSuccess && !passwordChangeSuccess && (
            <>
              {" "}
              <p className="text-[#CADCE6] mb-4 text-center">
                {t("passwordResetCodeSent")}
              </p>
              <div className="mt-4">
                <label
                  htmlFor="VerificationCode"
                  className="block text-sm font-medium text-[#CADCE6]"
                >
                  {t("verified_code")}
                </label>
                <input
                  type="text"
                  value={verificationCode}
                  onChange={(e) => {
                    setVerificationCode(e.target.value);
                    setError("");
                  }}
                  className="mt-1 block w-full px-3 py-2 bg-[transparent] border border-[#CADCE6] rounded-md text-base text-[#CADCE6] focus:outline-none focus:border-[#1DBF73] h-12"
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="Password"
                  className="block text-sm font-medium text-[#CADCE6]"
                >
                  {t("newPassword")}
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    checkPasswordRequirements(e.target.value, confirmPassword);
                  }}
                  className="mt-1 block w-full px-3 py-2 bg-[transparent] border border-[#CADCE6] rounded-md text-base text-[#CADCE6] focus:outline-none focus:border-[#1DBF73] h-12"
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="Password"
                  className="block text-sm font-medium text-[#CADCE6]"
                >
                  {t("confirmPassword")}
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    checkPasswordRequirements(newPassword, e.target.value);
                  }}
                  className="mt-1 block w-full px-3 py-2 bg-[transparent] border border-[#CADCE6] rounded-md text-base text-[#CADCE6] focus:outline-none focus:border-[#1DBF73] h-12"
                />
              </div>
              <div className="mt-4 text-center text-sm font-medium text-red-500">
                {passwordErrorMessage} {error}
              </div>
              <div className="flex items-center justify-between mt-6">
                <button
                  onClick={completeForgotPasswordChange}
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1DBF73]"
                  disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? t("loading") : t("resetPassword")}
                </button>
              </div>
            </>
          )}

          {passwordChangeSuccess && (
            <>
              {" "}
              <p className="text-[#CADCE6] mb-4 text-center">
                {t("passwordSuccessfullyChanged")}
              </p>
              <a
                onClick={toggleChangeForgotPassword}
                className="block text-sm font-medium text-[#CADCE6] text-center mt-[20px] cursor-pointer w-full"
              >
                {t("backToSignIn")}
              </a>
            </>
          )}

          {!showForgotPassword &&
            !isChangingPassword &&
            !passwordResetSuccess &&
            !passwordChangeSuccess && (
              <>
                <a
                  href="/#intro"
                  className="block text-sm font-medium text-[#CADCE6] text-center mt-[20px] cursor-pointer w-full underline"
                >
                  {t("dontHaveAnAccount")}
                </a>
                <a
                  className="block text-sm font-medium text-[#CADCE6] text-center mt-[20px] cursor-pointer"
                  onClick={toggleForgotPassword}
                >
                  {t("forgotYourPassword")}
                </a>

                <a
                  href="/"
                  className="block text-sm font-medium text-[#CADCE6] text-center mt-[20px] cursor-pointer w-full"
                >
                  {t("backToHome")}
                </a>
              </>
            )}
        </div>
      </div>
    </>
  );
};

export default LogIn;
