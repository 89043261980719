import React, { useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CheckoutForm = ({ productName, price }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState(""); // State to hold the email input value
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState("");
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const [termAndPolicyOpen, setTermAndPolicyOpen] = useState(false);
  const [termOpen, setTermOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { t, i18n } = useTranslation();

  const handlePolicyCheck = () => {
    setIsPolicyAccepted(!isPolicyAccepted);
  };

  const toggleTermAndPolicyModal = () => {
    setTermAndPolicyOpen(!termAndPolicyOpen);
  };

  const toggleTermModal = () => {
    setTermOpen(!termOpen);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isPolicyAccepted) {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setPaymentStatus("Payment loading...");
      // Call your backend to create the PaymentIntent
      const { clientSecret, error: backendError } = await fetch(
        "https://8bot-api.vercel.app/create-payment-intent-card",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ amount: parseInt(price) * 100, email }), // Include the email in the request
        }
      ).then((response) => response.json());

      if (backendError) {
        return;
      }

      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);

      // Use the clear method to get the values from the Stripe elements
      const { token, error } = await stripe.createToken(cardNumberElement);

      if (error) {
      } else {
        // The card has been verified successfully...
        const { paymentIntent, confirmError } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: {
              card: {
                token: token.id,
              },
            },
          }
        );

        if (confirmError) {
        } else {
          if (paymentIntent && paymentIntent.status == "succeeded") {
            try {
              const response = await fetch(
                "https://8bot-api.vercel.app/api/payment",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    userId: paymentIntent.id,
                    amount: paymentIntent.amount,
                    paymentDate: new Date().toISOString(), // Add payment date
                    paymentMethod: "creditcard",
                    email: email,
                    product: productName,
                  }),
                }
              );
              const data = await response.json();
            } catch (error) {
              console.error("Error posting payment details to server:", error);
            }
            try {
              const language = "en";
              const statusResponse = await fetch(
                "https://8bot-api.vercel.app/api/statusAnalysis",
                {
                  method: "POST",
                  body: JSON.stringify({
                    status: "noAnalysis",
                    email,
                    language,
                  }),
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
            } catch (error) {
              console.error("Error uploading files:", error);
            }
            setPaymentStatus("");
            localStorage.setItem("productName", productName);
            navigate(
              `/thankyou?redirect_status=${paymentIntent.status}&email=${email}`
            );
          } else {
            navigate(`/thankyou?redirect_status=failed`);
          }
        }
      }
    } else {
      setShowErrorMessage("Please accept the policy before submitting");
      setTimeout(() => {
        setShowErrorMessage("");
      }, 2000);
    }
  };

  // Handle changes to the email input field
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-[#CADCE6]"
        >
          {t("email")}
        </label>
        <input
          type="email"
          id="email"
          className="mt-1 block w-full px-3 py-1 bg-[#0F1C2D] border border-[#CADCE6] rounded-md text-base text-[#CADCE6]"
          value={email}
          onChange={handleEmailChange}
          required
        />
      </div>

      {/* Card Number Input */}
      <div>
        <label
          htmlFor="card-number"
          className="block text-sm font-medium text-[#CADCE6]"
        >
          Card Number
        </label>
        <CardNumberElement
          id="card-number"
          className="mt-1 block w-full px-3 py-2 bg-[#0F1C2D] border border-[#CADCE6] rounded-md text-base text-[#CADCE6] focus:outline-none focus:border-[#1DBF73]"
          options={{
            style: {
              base: {
                color: "#CADCE6",
                "::placeholder": {
                  color: "#666E7B",
                },
              },
              invalid: {
                color: "#FF5A5F",
              },
            },
            showIcon: true,
          }}
        />
      </div>

      {/* Expiry Date and CVC Input */}
      <div className="flex justify-between space-x-3">
        <div className="w-1/2">
          <label
            htmlFor="card-expiry"
            className="block text-sm font-medium text-[#CADCE6]"
          >
            Expiry Date (MM/YY)
          </label>
          <CardExpiryElement
            id="card-expiry"
            className="mt-1 block w-full px-3 py-2 bg-[#0F1C2D] border border-[#CADCE6] rounded-md text-base text-[#CADCE6] focus:outline-none focus:border-[#1DBF73]"
            options={{
              style: {
                base: {
                  color: "#CADCE6",
                  "::placeholder": {
                    color: "#666E7B",
                  },
                },
                invalid: {
                  color: "#FF5A5F",
                },
              },
            }}
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="card-cvc"
            className="block text-sm font-medium text-[#CADCE6]"
          >
            CVC
          </label>
          <CardCvcElement
            id="card-cvc"
            className="mt-1 block w-full px-3 py-2 bg-[#0F1C2D] border border-[#CADCE6] rounded-md text-base text-[#CADCE6] focus:outline-none focus:border-[#1DBF73]"
            options={{
              style: {
                base: {
                  color: "#CADCE6",
                  "::placeholder": {
                    color: "#666E7B",
                  },
                },
                invalid: {
                  color: "#FF5A5F",
                },
              },
            }}
          />
        </div>
      </div>

      {/* Submit Button */}
      <div>
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="policyCheckbox"
            className="mr-2"
            checked={isPolicyAccepted}
            onChange={() => setIsPolicyAccepted(!isPolicyAccepted)}
          />
          <label htmlFor="policyCheckbox" className="text-sm text-[#CADCE6]">
            {t("agree")}{" "}
            <span
              className="underline cursor-pointer"
              onClick={toggleTermAndPolicyModal}
            >
              {t("privacy")}
            </span>{" "}
            {t("and")}{" "}
            <span
              className="underline cursor-pointer"
              onClick={toggleTermModal}
            >
              {t("term")}
            </span>
          </label>
        </div>

        {showErrorMessage && (
          <div className="text-red-500 text-sm mb-4">{t("pay_error_msg")}</div>
        )}
        <button
          type="submit"
          className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1DBF73]"
        >
          {t("pay_btn")}
        </button>
      </div>

      <div>{paymentStatus}</div>

      {termAndPolicyOpen && (
        <div className="overlay" onClick={toggleTermAndPolicyModal}>
          <div className="modal mx-4">
            {/* Left Section */}

            <h1 className="text-2xl font-bold mb-4">
              {t("privacyPolicy.title")}
            </h1>

            <p className="mb-4">
              1. {t("privacyPolicy.title_1")}
              <br />
              {t("privacyPolicy.content_1")}
            </p>
            <p className="mb-4">
              2. {t("privacyPolicy.title_2")}
              <br />
              {t("privacyPolicy.content_2")}
            </p>
            <p className="mb-4">
              3. {t("privacyPolicy.title_3")}
              <br />
              {t("privacyPolicy.content_3")}
            </p>
            <p className="mb-4">
              4. {t("privacyPolicy.title_4")}
              <br />
              {t("privacyPolicy.content_4")}
            </p>
            <p className="mb-4">
              5. {t("privacyPolicy.title_5")}
              <br />
              {t("privacyPolicy.content_5")}
            </p>
          </div>
          <div></div>
        </div>
      )}

      {termOpen && (
        <div className="overlay" onClick={toggleTermModal}>
          <div className="modal mx-4">
            {/* Left Section */}
            <h1 className="text-2xl font-bold mb-4">
              1. {t("termsOfService.1.title")}
            </h1>

            <p className="mb-4">
              1.1 {t("termsOfService.1.1.1")}
              <br /> <br />
              1.2 {t("termsOfService.1.1.2")}
              <br /> <br />
              1.3 {t("termsOfService.1.1.3")}
              <br /> <br />
              1.4 {t("termsOfService.1.1.4")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              2. {t("termsOfService.2.title")}
            </h1>

            <p className="mb-4">
              2.1 {t("termsOfService.2.2.1")}
              <br /> <br />
              2.2 {t("termsOfService.2.2.2")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              3. {t("termsOfService.3.title")}
            </h1>

            <p className="mb-4">
              3.1 {t("termsOfService.3.3.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              4. {t("termsOfService.4.title")}
            </h1>

            <p className="mb-4">
              4.1 {t("termsOfService.4.4.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              5. {t("termsOfService.5.title")}
            </h1>

            <p className="mb-4">
              5.1 {t("termsOfService.5.5.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              6. {t("termsOfService.6.title")}
            </h1>

            <p className="mb-4">
              6.1 {t("termsOfService.6.6.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              7. {t("termsOfService.7.title")}
            </h1>

            <p className="mb-4">
              7.1 {t("termsOfService.7.7.1")}
              <br /> <br />
              7.2 {t("termsOfService.7.7.2")}
            </p>
          </div>
          <div></div>
        </div>
      )}
    </form>
  );
};

export default CheckoutForm;
