import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import mammoth from "mammoth";
import Navbar from "../Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const ResultPage = () => {
  const [htmlContent, setHtmlContent] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(true);
  const { email, index, username, displayUserEmail } = useParams();
  const { t, i18n } = useTranslation();
  const [termAndPolicyOpen, setTermAndPolicyOpen] = useState(false);
  const [termOpen, setTermOpen] = useState(false);

  const toggleTermAndPolicyModal = () => {
    setTermAndPolicyOpen(!termAndPolicyOpen);
  };
  const toggleTermModal = () => {
    setTermOpen(!termOpen);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const downLoadFile = async () => {
    try {
      const response = await fetch(
        `https://8bot-api.vercel.app/api/doneAnalysis/downLoadResult/${email}/${index}`
      );
      const data = await response.json();

      // Check if the response is an array and contains at least one URL
      if (Array.isArray(data) && data.length > 0) {
        // Assuming you want to download the first URL in the array
        const fileUrl = data[0];
        const fileName = "file"; // You can specify the file name here

        // Create a temporary anchor element to trigger the download
        const downloadLink = document.createElement("a");
        downloadLink.href = fileUrl;
        downloadLink.download = "file"; // You can specify the file name here
        downloadLink.target = "_blank";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        console.error("File URLs not found in response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const canonicalUrl = `https://8bot.tech/analysis/${email}/${index}/${username}/${displayUserEmail}`;

  useEffect(() => {
    console.log(email, index);
    const fetchData = async () => {
      setLoadingStatus(true);
      try {
        const response = await fetch(
          `https://8bot-api.vercel.app/api/doneAnalysis/getEachResult/${email}/${index}`
        );
        const arrayBuffer = await response.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });
        setLoadingStatus(false);
        setHtmlContent(result.value);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    scrollToTop();
  }, [email]);

  return (
    <div className="flex flex-col min-h-screen bg-[#0F1C2D]">
      <Helmet>
        <title>Result - 8bot.tech</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Navbar
        isVisible={true}
        username={username}
        displayUserEmail={displayUserEmail}
      />
      <div className="flex justify-end mr-5">
        <button className="mt-24 text-white border border-[#1DBF73] rounded-lg flex items-center p-2" onClick={()=>{downLoadFile()}}>
          <FontAwesomeIcon icon={faDownload} />
        </button>
      </div>

      <div className="">
        {loadingStatus ? (
          <div className="flex flex-col items-center justify-center h-screen">
            <div className="spinner"></div>
          </div>
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            className="text-white mx-5 mt-4"
          />
        )}
      </div>

      <footer className="text-center p-8">
        <p className="text-[#CADCE6] font-bold text-sm sm:text-base mt-4">
          {t("openingHour")}
        </p>
        <p className="text-[#CADCE6] text-xs sm:text-sm mt-4">
          © 2024 8bot. {t("footer")}
        </p>
        <p className="mt-2">
          <a href="#" className="text-[#CADCE6] mx-2">
            <FontAwesomeIcon icon={faFacebook} className="w-6 h-6" />
          </a>
          <a href="#" className="text-[#CADCE6] mx-2">
            <FontAwesomeIcon icon={faInstagram} className="w-6 h-6" />
          </a>
        </p>
        <a href="mailto:eightbot88@hotmail.com" className="text-[#CADCE6] mt-2">
          eightbot88@hotmail.com
        </a>

        <div className="text-[#CADCE6] text-xs sm:text-sm cursor-pointer mt-4">
          <span className="underline" onClick={toggleTermAndPolicyModal}>
            {t("privacy")}
          </span>{" "}
          {t("and")}{" "}
          <span className="underline" onClick={toggleTermModal}>
            {t("term")}
          </span>
        </div>
      </footer>
    </div>
  );
};

export default ResultPage;
