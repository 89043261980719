import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { AuthContext } from "../context/AuthContext";
import { useTranslation } from "react-i18next";
const cookies = new Cookies();

const Navbar = ({ isVisible, username, displayUserEmail, isCloseDropdown }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [token, setToken] = useState("");
  const [state, dispatch] = useContext(AuthContext);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownOpenLang, setDropdownOpenLang] = useState(false);
  const [isDropdownOpenService, setDropdownOpenService] = useState(false);
  const howRef = useRef(null);

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/auth/login");
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleDropDownLang = () => {
    setDropdownOpenLang(!isDropdownOpenLang);
  };

  const toggleDropDownService = () => {
    setDropdownOpenService(!isDropdownOpenService);
    if (isDropdownOpenService) {
      const howLink = howRef.current;
      if (howLink) {
        const rect = howLink.getBoundingClientRect();
        const modal = document.getElementById("isDropdownOpenService");
        if (modal) {
          modal.style.right = `1000px`;
        }
      }
    }
  };
  const closeLang = () => {
    setDropdownOpenLang(false);
  };

  const closeDropDown = () => {
    setDropdownOpen(false);
  };
  const location = useLocation();

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    toggleDropDownLang();
  };
  const { t } = useTranslation();

  // Check if the current pathname matches a specific path
  const isHome = location.pathname === "/home";
  const isNotHome =
    location.pathname.includes("analysis") ||
    location.pathname.includes("uploadAnalysis") ||
    location.pathname.includes("pendingAnalysis");

  const isShowcase = location.pathname.includes("trading");
  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      // Set a default language if none is stored
      i18n.changeLanguage("en");
    }
    const token = cookies.get("token");
    setToken(token);

    if (isCloseDropdown == true) {
      setIsOpen(false);
    }
  }, [dispatch, isCloseDropdown]);

  return (
    <nav
      className={`fixed top-0 left-0 flex items-center justify-between flex-wrap px-5 py-6 transition-opacity duration-300 z-10  bg-[#0F1C2D] bg-opacity-90 ${
        isVisible ? "opacity-100" : "opacity-0"
      } ${isHome ? "custom-width" : "w-full"}`}
    >
      {isNotHome ? (
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <span className="font-semibold text-xl tracking-tight text-shadow">
            <a href="/home">8bot</a>
          </span>
        </div>
      ) : isShowcase ? (
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <span className="font-semibold text-xl tracking-tight text-shadow">
            <a href="/projectShowCases">8bot</a>
          </span>
        </div>
      ) : (
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <span className="font-semibold text-xl tracking-tight text-shadow">
            <a href="/">8bot</a>
          </span>
        </div>
      )}

      <div className="block lg:hidden flex">
        <div className="block lg:inline-block text-[#CADCE6] hover:text-white mr-2">
          <button
            className="p-2 w-10 h-10 text-white rounded-full flex items-center justify-center cursor-pointer focus:outline-none border  border-[#1DBF73]"
            onClick={() => {
              toggleDropDownLang();
              closeDropDown();
            }}
          >
            {i18n.language.toUpperCase()}
          </button>
        </div>
        <button
          onClick={() => {
            setIsOpen(!isOpen);
            closeLang();
          }}
          className="flex items-center px-3 py-2 text-white border-white hover:text-white hover:border-white"
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0zM0 9h20v2H0zM0 15h20v2H0z" />
          </svg>
        </button>
      </div>
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } w-full lg:flex lg:items-center lg:w-auto`}
      >
        <div className="text-sm lg:flex-grow">
          <a
            href="/#service-data"
            className="block mt-4 lg:mt-0 lg:inline-block text-[#CADCE6] hover:text-white mr-4"
          >
            {t("dataService")}
          </a>
          <div
            onClick={() => {
              toggleDropDownService();
            }}
            className="hidden md:block cursor-pointer mt-4 lg:mt-0 lg:inline-block text-[#CADCE6] hover:text-white mr-4 dropdown"
          >
            {t("how")}

            <div
              id="isDropdownOpenService"
              className="dropdown-content rounded-lg"
            >
              <a
                href="/#intro"
                className="block mt-4 lg:mt-0 lg:inline-block text-[#CADCE6] hover:text-white mr-4 w-full text-left px-4 py-2"
              >
                {t("dataAnalysis")}
              </a>
              <a
                href="/#prodive-data"
                className="block mt-4 lg:mt-0 lg:inline-block text-[#CADCE6] hover:text-white mr-4 w-full text-left px-4 py-2"
              >
                {t("providingData")}
              </a>
              <a
                href="/#provide-question"
                className="block mt-4 lg:mt-0 lg:inline-block text-[#CADCE6] hover:text-white mr-4 w-full text-left px-4 py-2"
              >
                {t("providingQuestions")}
              </a>
              <a
                href="/#waiting-result"
                className="block mt-4 lg:mt-0 lg:inline-block text-[#CADCE6] hover:text-white mr-4 w-full text-left px-4 py-2"
              >
                {t("awaitingResults")}
              </a>
            </div>
          </div>
          <a
            href="/projectShowCases"
            className="block mt-4 lg:mt-0 lg:inline-block text-[#CADCE6] hover:text-white mr-4 "
          >
            {t("showcase_title")}
          </a>{" "}
          <a
            href="/#intro"
            className=" mt-4 lg:mt-0 text-[#CADCE6] hover:text-white mr-4 md:hidden block"
          >
            {t("dataAnalysis")}
          </a>
          <a
            href="/#prodive-data"
            className="mt-4 lg:mt-0  text-[#CADCE6] hover:text-white mr-4 md:hidden block"
          >
            {t("providingData")}
          </a>
          <a
            href="/#provide-question"
            className=" mt-4 lg:mt-0  text-[#CADCE6] hover:text-white mr-4 md:hidden block"
          >
            {t("providingQuestions")}
          </a>
          <a
            href="/#waiting-result"
            className=" mt-4 lg:mt-0  text-[#CADCE6] hover:text-white mr-4 md:hidden block"
          >
            {t("awaitingResults")}
          </a>
          <a
            href="/#contact-us"
            className="block mt-4 lg:mt-0 lg:inline-block text-[#CADCE6] hover:text-white mr-4"
          >
            {t("contactUs")}
          </a>
        </div>

        <div className="block mt-4 lg:mt-0 lg:inline-block text-[#CADCE6] hover:text-white mr-4">
          <button
            className="p-2 w-10 h-10 text-white rounded-full flex items-center justify-center cursor-pointer focus:outline-none lg:block hidden border  border-[#1DBF73]"
            onClick={() => {
              toggleDropDownLang();
              closeDropDown();
            }}
          >
            {i18n.language.toUpperCase()}
          </button>
        </div>
        {isHome ? (
          <div>
            <button
              className="p-2 w-10 h-10 bg-[#1DBF73] text-white rounded-full flex items-center justify-center cursor-pointer focus:outline-none lg:block hidden"
              onClick={() => {
                toggleDropdown();
                closeLang();
              }}
            >
              {username}
            </button>
            <div className="flex justify-between lg:hidden block mt-2">
              <button className="p-2 w-10 h-10 bg-[#1DBF73] text-white rounded-full flex items-center justify-center cursor-pointer focus:outline-none">
                {username}
              </button>

              <button
                className="text-[#CADCE6] hover:bg-[#1DBF73] hover:text-[#0F1C2D] block w-full text-left px-4 py-2 focus:outline-none text-sm"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
        ) : (
          <div>
            {token ? (
              <a
                href="/home"
                className="inline-block text-sm px-4 py-2 leading-none rounded lg:mt-0 bg-[#1DBF73] text-white hover:bg-green-600 transition duration-300"
              >
                {t("dashboard")}
              </a>
            ) : (
              <a
                href="/auth/login"
                className="inline-block text-sm px-4 py-2 leading-none rounded lg:mt-0 bg-[#1DBF73] text-white hover:bg-green-600 transition duration-300"
              >
                {t("login")}
              </a>
            )}
          </div>
        )}

        {isDropdownOpen && (
          <div className="absolute top-20 right-8 w-40 border border-[#12263F] text-[#CADCE6] bg-[#12263F] rounded-lg">
            <div className="px-4 py-2 text-[#CADCE6] flex items-center">
              <div className="w-8 h-8 bg-[#1DBF73] text-white rounded-full flex items-center justify-center mr-2 text-sm">
                {username}
              </div>
              <small>{displayUserEmail}</small>
            </div>
            <button
              className="text-[#CADCE6] hover:bg-[#1DBF73] hover:text-[#0F1C2D] block w-full text-left px-4 py-2 focus:outline-none"
              onClick={handleLogout}
            >
              {t("logout")}
            </button>
          </div>
        )}
      </div>

      {isDropdownOpenLang && (
        <div className="absolute top-20 right-8 w-40 border border-[#12263F] text-[#CADCE6] bg-[#12263F] rounded-lg">
          <button
            className="text-[#CADCE6] hover:bg-[#1DBF73] hover:text-[#0F1C2D] block w-full text-left px-4 py-2 focus:outline-none text-sm"
            onClick={() => changeLanguage("en")}
          >
            EN
          </button>
          <button
            className="text-[#CADCE6] hover:bg-[#1DBF73] hover:text-[#0F1C2D] block w-full text-left px-4 py-2 focus:outline-none text-sm"
            onClick={() => changeLanguage("ms")}
          >
            MS
          </button>
          <button
            className="text-[#CADCE6] hover:bg-[#1DBF73] hover:text-[#0F1C2D] block w-full text-left px-4 py-2 focus:outline-none text-sm"
            onClick={() => changeLanguage("zh")}
          >
            ZH
          </button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
