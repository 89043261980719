// ProjectShowcasePage.js
import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { useTranslation } from "react-i18next";
import "./ProjectShowCase.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
const ProjectShowcasePage = () => {
  const navigate = useNavigate();
  const [navbarVisible, setNavbarVisible] = useState(true);
  const { t, i18n } = useTranslation();
  const [showcaseTypes, setShowcaseTypes] = useState([]);
  const [showCase, setShowCase] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleShowcaseTypeClick = (showcaseType) => {
    // setSelectedShowcaseType(showcaseType);
  };

  const goSubPage = (id) => {
    navigate(`/trading/${id}`);
  };
  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      // Set a default language if none is stored
      i18n.changeLanguage("en");
    }

    const fetchShowcaseTypes = async () => {
      try {
        const response = await fetch("https://8bot-api.vercel.app/api/showcasetype");
        const data = await response.json();
        setShowcaseTypes(data);
        fetchShowcase(data[0]);
      } catch (error) {
        console.error("Error fetching showcase types:", error);
      }
    };
    fetchShowcaseTypes();

    const fetchShowcase = async (type) => {
      try {
        let lang;
        if (i18n.language) {
          lang = i18n.language;
        } else {
          lang = "en";
        }
        const response = await fetch(
          `https://8bot-api.vercel.app/api/showcase?lang=${lang}&type=${type}`
        );
        const data = await response.json();
        setShowCase(data.showcases);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching showcase data:", error);
        setLoading(false); // Set loading to false once data is fetched

        return null;
      }
    };
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      // Set the navbar to not visible when the page is scrolled down
      const currentScrollY = window.scrollY;

      // Set navbar to not visible when scrolling down
      if (currentScrollY > lastScrollY) {
        setNavbarVisible(false);
      } else {
        setNavbarVisible(true);
      }

      // Update lastScrollY to current scroll position
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navbarVisible, i18n.language]);

  return (
    <div className="font-sans bg-[#0F1C2D] text-[#E5F4FF] min-h-screen">
      <Helmet>
        <title>Showcases - 8bot.tech</title>
        <link rel="canonical" href="https://8bot.tech/projectShowCases" />
      </Helmet>
      <Navbar isVisible={navbarVisible} />
      <div className=" pt-14">
        <div className=" px-5 py-8">
          <h2 className="text-[#E5F4FF] text-2xl sm:text-3xl md:text-4xl font-bold">
            {t("showcase_title")}
          </h2>

          <div className="mx-auto flex">
            <div className="flex overflow-x-auto scrollbar py-5">
              {showcaseTypes.map((showcaseType, index) => (
                <button
                  key={index}
                  className="border border-[#1DBF73]  py-2 px-4 rounded-full mr-4 "
                  onClick={() => handleShowcaseTypeClick(showcaseType)}
                >
                  {t(showcaseType)}
                </button>
              ))}
            </div>
          </div>

          {loading ? (
            <div className="flex items-center justify-center pt-10">
              <div className="spinner"></div>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-white ">
              {showCase.map((item, index) => (
                <div
                  key={index}
                  className="bg-[#0F1C2D] border border-[#1DBF73] h-[350px] rounded-lg cursor-pointer"
                  onClick={() => goSubPage(item._id)}
                >
                  <img
                    src={item.imageUrl}
                    alt={item.title}
                    className="w-full h-2/3 object-cover rounded-t-lg"
                  />
                  <div className="p-4">
                    <h2 className="text-xl font-boldtext-blue-50">
                      {item.title}
                    </h2>
                    <p>{item.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProjectShowcasePage;
