// NewPage.js
import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import { Helmet } from "react-helmet";

const FileUploadSection = ({ setValidFileUpload, isClicked, setDataFiles }) => {
  const [files, setFiles] = useState([]);
  const [showError, setShowError] = useState(false);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      let validFiles = [];
      let invalidFiles = [];

      acceptedFiles.forEach((file) => {
        if (file.size <= 1024 * 1024 * 1024) {
          // Check if file size is less than or equal to 1GB (1024 bytes * 1024 kilobytes * 1024 megabytes)
          validFiles.push(file);
        } else {
          invalidFiles.push(file);
        }
      });

      if (invalidFiles.length === 0) {
        setFiles((prevFiles) => [...prevFiles, ...validFiles]);
        setDataFiles((prevDataFiles) => [...prevDataFiles, ...validFiles]);
        setValidFileUpload(true);
        setShowError(false);
      } else {
        // Set error state
        setShowError(true);
        console.log("Invalid files:", invalidFiles);
      }
    },
    multiple: true,
  });

  const { t } = useTranslation();
  return (
    <div
      className={`h-full mt-24 text-[#CADCE6] m-6 ${
        isClicked && files.length === 0
          ? "border-red-500"
          : "border-transparent"
      }`}
    >
      <h2 className="text-[#E5F4FF] text-3xl font-bold text-center">
        {t("yourDataForAnalysis")}
      </h2>
      <div
        {...getRootProps()}
        className={`border-dashed border-2 ${
          files.length === 0 ? "border-red-500" : "border-accent-color"
        } rounded-lg p-8 flex flex-col justify-center items-center mt-5`}
      >
        <input {...getInputProps()} multiple />

        {isDragActive ? (
          <p className="text-content"> {t("dropFiles")}</p>
        ) : (
          <p className="text-content">{t("dropFilesHere")}</p>
        )}
        <FiUpload className="text-button-color text-4xl mt-4" />
      </div>
      {files.length > 0 && (
        <div className="mt-8">
          <h5 className="text-[#E5F4FF]  font-bold text-center">
            {t("uploadedFiles")}
          </h5>
          <ul>
            {files.map((file, index) => (
              <li
                key={index}
                className="text-content flex items-center justify-between"
              >
                <span>{file.name}</span>
                <button
                  className="text-red-500 hover:text-red-700"
                  onClick={() => {
                    const newFiles = [...files];
                    newFiles.splice(index, 1);
                    setFiles(newFiles);
                    setValidFileUpload(newFiles.length > 0);
                  }}
                >
                  {t("delete")}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {isClicked && files.length === 0 && (
        <p className="text-red-500"> {t("uploadAtLeastOneFile")}</p>
      )}
      {showError && <p className="text-red-500 mt-8">{t("exceed")}</p>}
    </div>
  );
};

const QuestionFileUploadSection = ({
  setValidQuestionUpload,
  isClicked,
  setQuestionFiles,
}) => {
  const [files, setFiles] = useState([]);
  const [showError, setShowError] = useState(false);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0]; // Assuming only one file is allowed
      const fileSizeInKB = file.size / (1024 * 1024 * 1024);
      if (fileSizeInKB <= 1) {
        setFiles([file]);
        setQuestionFiles([file]);
        setValidQuestionUpload(true);
        setShowError(false);
      } else {
        setShowError(true);
        setValidQuestionUpload(false);
      }
    },
    multiple: false,
  });
  const { t } = useTranslation();

  return (
    <div
      className={`h-full mt-24 text-[#CADCE6] m-6 ${
        isClicked && files.length === 0
          ? "border-red-500"
          : "border-transparent"
      }`}
    >
      <h2 className="text-[#E5F4FF] text-3xl font-bold text-center">
        {t("yourQuestions")}
      </h2>
      <div
        {...getRootProps()}
        className={`border-dashed border-2 ${
          files.length === 0 ? "border-red-500" : "border-accent-color"
        } rounded-lg p-8 flex flex-col justify-center items-center mt-5`}
      >
        <input {...getInputProps()} />

        {isDragActive ? (
          <p className="text-content"> {t("dropFiles")}</p>
        ) : (
          <p className="text-content">{t("dropFilesHere")}</p>
        )}
        <FiUpload className="text-button-color text-4xl mt-4" />
      </div>
      {files.length > 0 && (
        <div className="mt-8">
          <h5 className="text-[#E5F4FF]  font-bold text-center">
            {t("uploadedFiles")}
          </h5>
          <ul>
            {files.map((file, index) => (
              <li
                key={index}
                className="text-content flex items-center justify-between"
              >
                <span>{file.name}</span>
                <button
                  className="text-red-500 hover:text-red-700"
                  onClick={() => {
                    const newFiles = [...files];
                    newFiles.splice(index, 1);
                    setFiles(newFiles);
                    setValidQuestionUpload(newFiles.length > 0);
                  }}
                >
                  {t("delete")}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {isClicked && files.length === 0 && (
        <p className="text-red-500"> {t("uploadAtLeastOneFile")}</p>
      )}
      {showError && <p className="text-red-500 mt-8">{t("exceed")}</p>}
    </div>
  );
};
const UploadDataAnalysis = () => {
  const { email, index, username, displayUserEmail } = useParams();
  const { t, i18n } = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [questionFiles, setQuestionFiles] = useState([]);
  const [dataFiles, setDataFiles] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [validFileUpload, setValidFileUpload] = useState(false);
  const [validQuestionUpload, setValidQuestionUpload] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isConfirmDialog, setConfirmDialog] = useState(false);
  const [language, setSelectedLanguage] = useState("en"); // Default language is English
  const navigate = useNavigate();

  const goHomePage = () => {
    navigate("/home");
  };
  const handleAnalysisClick = () => {
    if (validFileUpload && validQuestionUpload) {
      setConfirmDialog(!isConfirmDialog);
    }
  };

  const handleSubmit = async () => {
    const allFiles = [...questionFiles, ...dataFiles];
    const formData = new FormData();
    allFiles.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("email", email);

    try {
      setUploading(true);
      const response = await fetch(
        "https://8bot-api.vercel.app/api/uploadFile",
        {
          method: "POST",
          body: formData,
        }
      );
      if (response.ok) {
        const statusResponse = await fetch(
          `https://8bot-api.vercel.app/api/statusAnalysis/UpdateFirst/${email}`,
          {
            method: "PUT",
            body: JSON.stringify({
              status: "pendingAnalysis",
              language,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (statusResponse.ok) {
          // Send the email here
          const emailResponse = await fetch(
            "https://8bot-api.vercel.app/send-email",
            {
              method: "POST",
              body: JSON.stringify({
                email,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (!emailResponse.ok) {
            console.error("Sending email failed:", emailResponse.statusText);
          }
        } else {
          console.error("Sending status failed:", statusResponse.statusText);
        }
        setUploading(false);
        setIsDone(true);
      } else {
        // Handle upload error
      }
      setUploading(false);
    } catch (error) {
      console.error("Error uploading files:", error);
      setUploading(false);
      // Handle upload error
    }
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    // You can add logic here to set the language in your app
  };
  const canonicalUrl = `https://8bot.tech/uploadAnalysis/${email}/${username}/${displayUserEmail}`;

  return (
    <div className="flex flex-col min-h-screen bg-[#0F1C2D]">
      <Helmet>
        <title>DataAnalysis - 8bot</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Navbar
        isVisible={true}
        username={username}
        displayUserEmail={displayUserEmail}
      />
      <div className="md:flex flex-col flex-1">
        <div className=" md:flex">
          <div className="md:w-1/2 ">
            <FileUploadSection
              setValidFileUpload={setValidFileUpload}
              isClicked={isClicked}
              setDataFiles={setDataFiles}
            />
          </div>
          <div className="md:w-1/2">
            <QuestionFileUploadSection
              setValidQuestionUpload={setValidQuestionUpload}
              isClicked={isClicked}
              setQuestionFiles={setQuestionFiles}
            />
          </div>
        </div>
        <div className="flex justify-center items-center mb-5 px-5">
          <span className="text-white mr-4">{t("langAnalysis")}</span>
          <select
            className="text-white bg-[#0F1C2D] border border-[#1DBF73] rounded-md py-2 px-4"
            value={language}
            onChange={(e) => handleLanguageChange(e.target.value)}
          >
            <option value="en">English</option>
            <option value="zh">中文</option>
            <option value="ms">Malay</option>
          </select>
        </div>

        <div className="flex justify-center mb-5">
          <button
            type="submit"
            className="w-1/2 flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 "
            onClick={handleAnalysisClick}
          >
            {t("clickForAnalysis")}
          </button>
        </div>
      </div>

      {isConfirmDialog && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-900">
          <div className="bg-[#0F1C2D] text-white  p-8 rounded-lg min-w-[350px]">
            {!isDone && !uploading && (
              <>
                <h2 className="text-2xl font-semibold mb-4">
                  {" "}
                  {t("confirmUploadHeading")}
                </h2>
                <p className="text-[#CADCE6]  text-sm mb-4">
                  {t("confirmUploadMessage")}
                </p>
              </>
            )}
            {!isDone && !uploading && (
              <div className="mt-4 flex justify-between">
                <button
                  className="px-4 py-2 bg-[#1DBF73] text-white rounded focus:outline-none w-1/2"
                  onClick={handleSubmit}
                >
                  {t("confirmButtonText")}
                </button>
                <button
                  className="px-4 py-2 bg-gray-300 text-gray-600 rounded ml-4 focus:outline-none w-1/2"
                  onClick={handleAnalysisClick}
                >
                  {t("cancelButtonText")}
                </button>
              </div>
            )}

            {uploading && !isDone && (
              <div className="flex items-center justify-center mt-4">
                {t("uploadingMessage")}
                <div className="spinner ml-5"></div>
              </div>
            )}

            {isDone && !uploading && (
              <div className="">
                <div className="flex items-center justify-center">
                  {t("doneMessage")}
                </div>
                <button
                  className="px-4 py-2 bg-[#1DBF73] text-white rounded focus:outline-none w-full mt-4"
                  onClick={goHomePage}
                >
                  {t("doneButtonText")}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadDataAnalysis;
