// FinancialSubpage.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import mammoth from "mammoth";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
import "./subcase.css";
const TradingSubPage = () => {
  let { id } = useParams();
  const [htmlContent, setHtmlContent] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [navbarVisible, setNavbarVisible] = useState(true);

  useEffect(() => {
    // let lastScrollY = window.scrollY;

    // const handleScroll = () => {
    //   // Set the navbar to not visible when the page is scrolled down
    //   const currentScrollY = window.scrollY;

    //   // Set navbar to not visible when scrolling down
    //   if (currentScrollY > lastScrollY) {
    //     setNavbarVisible(false);
    //   } else {
    //     setNavbarVisible(true);
    //   }

    //   // Update lastScrollY to current scroll position
    //   lastScrollY = currentScrollY;
    // };

    // window.addEventListener("scroll", handleScroll);
    const fetchData = async () => {
      setLoadingStatus(true);
      try {
        const response = await fetch(
          `https://8bot-api.vercel.app/api/showcase/getDoc/${id}`
        );
        const arrayBuffer = await response.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });
        setLoadingStatus(false);
        setHtmlContent(result.value);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, [navbarVisible]);
  const canonicalUrl = `https://8bot.tech/trading/${id}`;

  return (
    <div className="flex flex-col bg-[#0F1C2D]">
      <Helmet>
        <title>ShowCase-Trading-8bot.tech</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Navbar isVisible={navbarVisible} />
      <div className="">
        {loadingStatus ? (
          <div className="flex flex-col items-center justify-center h-screen">
            <div className="spinner"></div>
          </div>
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            className="text-white mx-5 mt-28"
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default TradingSubPage;
