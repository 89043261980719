import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUpload, faClock } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Card = ({ email, username, displayUserEmail }) => {
  const [analyses, setAnalyses] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [uploadanalysis, setUploadAnalysis] = useState(false);
  const [noAnalysis, setNoAnalysis] = useState([]);
  const [pendingAnalyses, setPendingAnalyses] = useState([]);
  const [showBuy, setShowBuy] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      setLoadingStatus(true);
      try {
        const response = await fetch(
          `https://8bot-api.vercel.app/api/statusAnalysis/statusArray/${email}`
        );
        if (response.ok) {
          const data = await response.json();
          setAnalyses(data);
          const pendingAnalyses = data.filter(
            (status) => status === "pendingAnalysis"
          );
          const noAnalysis = data.filter((status) => status === "noAnalysis");
          setPendingAnalyses(pendingAnalyses);
          setNoAnalysis(noAnalysis);
        } else {
          console.error("Failed to fetch analyses");
        }
      } catch (error) {
        console.error("Error fetching analyses:", error);
      }
      try {
        const response = await fetch(
          "https://8bot-api.vercel.app/api/doneAnalysis/totalItems/" + email
        );
        const data = await response.json(); // Parse response body as JSON
        setLoadingStatus(false);
        if (data && !data.error) {
          setAnalyses(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      try {
        const response = await fetch(
          "https://8bot-api.vercel.app/api/payment/" + email
        );
        const data = await response.json();
        if (analyses != data && !pendingAnalyses) {
          setUploadAnalysis(true);
        }
        setLoadingStatus(false);
        setShowBuy(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [email]);

  const handleReadMore = (index) => {
    navigate(`/analysis/${email}/${index}/${username}/${displayUserEmail}`); // Navigate to new page
  };

  const handleUpload = () => {
    navigate(`/uploadAnalysis/${email}/${username}/${displayUserEmail}`); // Navigate to new page
  };

  const handlePending = () => {
    navigate(`/pendingAnalysis/${email}/${username}/${displayUserEmail}`); // Navigate to new page
  };
  const handleCheckout = () => {
    navigate("/#intro");
  };
  return (
    <>
      {loadingStatus ? (
        <div className="flex flex-col items-center justify-center h-screen">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4 mt-24 ]">
          {/* Desktop Cards */}
          {analyses.totalItems > 0 && (
            <>
              {Array.from({ length: analyses.totalItems }, (_, index) => (
                <div
                  key={index}
                  className="md:block bg-[#0F1C2D] text-[#CADCE6] rounded-lg border border-[#1DBF73] min-h-[200px] cursor-pointer flex items-center justify-center p-10"
                  onClick={() => handleReadMore(index)}
                >
                  <div className="text-center">
                    <h2
                      className="text-xl font-semibold mb-4"
                      style={{ color: "#E5F4FF" }}
                    >
                      {email}
                    </h2>
                    <p className="mb-4">
                      {t("analysisReport")} {index}
                    </p>
                    <button
                      className="bg-[#1DBF73] text-white px-4 py-2 rounded-md"
                      style={{ backgroundColor: "#1DBF73", color: "#FFFFFF" }}
                    >
                      {t("readMore")}
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}
          {pendingAnalyses.length > 0 && (
            <>
              {Array.from({ length: pendingAnalyses.length }, (_, index1) => (
                <div
                  key={index1}
                  className="bg-[#0F1C2D] text-[#CADCE6] p-6 rounded-lg border border-[#1DBF73] relative min-h-[200px] cursor-pointer"
                  onClick={() => handlePending()}
                >
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                    <FontAwesomeIcon
                      icon={faClock}
                      className="text-[#1DBF73] text-4xl "
                    />
                    <h2
                      className="text-xl font-semibold mt-4"
                      style={{ color: "#E5F4FF" }}
                    >
                      {t("pendingAnalysis")}
                    </h2>
                  </div>
                </div>
              ))}
            </>
          )}

          {noAnalysis.length > 0 && (
            <>
              {Array.from({ length: noAnalysis.length }, (_, index2) => (
                <div
                  key={index2}
                  className="bg-[#0F1C2D] text-[#CADCE6] p-6 rounded-lg border border-[#1DBF73] relative min-h-[200px] cursor-pointer"
                  onClick={() => handleUpload()}
                >
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                    <FontAwesomeIcon
                      icon={faUpload}
                      className="text-[#1DBF73] text-4xl"
                    />
                    <h2
                      className="text-xl font-semibold mt-4"
                      style={{ color: "#E5F4FF" }}
                    >
                      {t("upload")}
                    </h2>
                  </div>
                </div>
              ))}
            </>
          )}

          {showBuy && (
            <div
              className=" bg-[#0F1C2D] text-[#CADCE6] p-6 rounded-lg border border-[#1DBF73] relative min-h-[200px] cursor-pointer"
              onClick={() => handleCheckout()}
            >
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                <FontAwesomeIcon
                  icon={faLock}
                  className="text-[#1DBF73] text-4xl "
                />
                <h2
                  className="text-xl font-semibold mt-4"
                  style={{ color: "#E5F4FF" }}
                >
                  {t("clickBuy")}
                </h2>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Card;
