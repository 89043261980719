// Import React and necessary Tailwind CSS classes
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import ReactPixel from 'react-facebook-pixel';

const ThankYouPage = () => {
  const [thankYou, setThankYou] = useState("");
  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState("");
  const { t, i18n } = useTranslation();
  const [redirectStatus, setRedirectStatus] = useState();
  const [goDashboard, setGoDashboard] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      // Set a default language if none is stored
      i18n.changeLanguage("en");
    }

    if (!localStorage.getItem("productName")) {
      navigate("/");
    }
    const fetchData = async () => {
      const params = new URLSearchParams(location.search);
      const redirectStatus = params.get("redirect_status");
      setRedirectStatus(redirectStatus);
      const amount = params.get("amount");
      const email = params.get("email");
      const method = params.get("method");
      const payment_intent = params.get("payment_intent");

      setPaymentStatus(redirectStatus);

      const storedProductName = localStorage.getItem("productName");
      if (method && redirectStatus != "failed") {
        try {
          const response = await fetch("https://8bot-api.vercel.app/api/payment", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: payment_intent,
              amount: amount,
              paymentDate: new Date().toISOString(), // Add payment date
              paymentMethod: method,
              email: email,
              product: storedProductName,
            }),
          });
          ReactPixel.track('CompletePayment');
          const data = await response.json();
          sendInvitationEmail(email);
        } catch (error) {
          console.error("Error posting payment details to server:", error);
        }

        try {
          const language = "en";
          const statusResponse = await fetch(
            "https://8bot-api.vercel.app/api/statusAnalysis",
            {
              method: "POST",
              body: JSON.stringify({
                status: "noAnalysis",
                email,
                language,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        } catch (error) {
          console.error("Error uploading files:", error);
        }
      } else if (redirectStatus != "failed") {
        sendInvitationEmail(email);
      }
    };
    setTimeout(() => {
      setShowThankYou(true);
    }, 1000);
    fetchData();
  }, [location.search]);

  const sendInvitationEmail = async (email) => {
    const storedProductName = localStorage.getItem("productName");

    try {
      const response = await fetch(
        "https://8bot-api.vercel.app/api/sendEmailInvitation",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
          }),
        }
      );

      if (response.ok) {
        if (storedProductName) {
          if (redirectStatus === "failed") {
            setThankYou(t("thank_fail_content"));
          } else {
            if (storedProductName === "All Symbol Chatroom") {
              setThankYou(t("thank_success_content"));
            } else {
              setThankYou(t("thank_success_content"));
            }
          }

          localStorage.removeItem("productName");
        }
        console.log("Invitation email sent successfully");
      } else {
        setThankYou(t("thank_go_dashboard"));
        setGoDashboard(true);
        throw new Error("Failed to send invitation email");
      }
    } catch (error) {
      console.error("Error sending invitation email:", error);
    }

    setShowThankYou(true);
  };
  const handleClick = () => {
    navigate("/");
  };

  const handleGoDashboard = () => {
    navigate("/home");
  };

  return (
    <div className="bg-[#0F1C2D] min-h-screen flex flex-col items-center justify-center text-[#CADCE6] px-5">
      <Helmet>
        <title>Thank You - 8bot.tech</title>
        <link rel="canonical" href="https://8bot.tech/thankyou" />
      </Helmet>
      {showThankYou ? (
        <>
          <h1 className="text-[#E5F4FF] font-bold text-4xl md:text-5xl mb-4 font-sans flex justify-center">
            {paymentStatus != "failed" ? (
              <span className="text-center">{t("thank_title")}</span>
            ) : (
              <span className="text-center">{t("thank_fail_title")}</span>
            )}
          </h1>
          <p className="max-w-md text-center mb-6 text-lg md:text-xl font-light">
            {thankYou}
          </p>
          {goDashboard ? (
            <button
              className="bg-[#1DBF73] text-white font-bold py-2 px-4 rounded hover:bg-green-700 transition duration-300 ease-in-out text-md md:text-lg w-1/3"
              onClick={() => handleGoDashboard()}
            >
              {t("continue")}
            </button>
          ) : (
            <button
              className="bg-[#1DBF73] text-white font-bold py-2 px-4 rounded hover:bg-green-700 transition duration-300 ease-in-out text-md md:text-lg w-1/3"
              onClick={handleClick}
            >
              {t("continue")}
            </button>
          )}
        </>
      ) : (
        <div className="flex items-center justify-center ">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

export default ThankYouPage;
