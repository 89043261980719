import React from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../Navbar";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

const PendingAnalysisPage = () => {
  const { t, i18n } = useTranslation();
  const { email, index, username, displayUserEmail } = useParams();
  const canonicalUrl = `https://8bot.tech/pendingAnalysis/${email}/${username}/${displayUserEmail}`;

  return (
    <div className="flex flex-col min-h-screen bg-[#0F1C2D]">
      <Helmet>
        <title>DataAnalysis - 8bot.tech</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Navbar
        isVisible={true}
        username={username}
        displayUserEmail={displayUserEmail}
      />

      <div className="flex flex-col items-center justify-center h-screen text-white">
        <img
          src="/data_pending_analysis.png"
          className="w-24 h-24 mb-4"
          alt="Data Analysis"
        />
        <p className="text-center px-4">{t("spinnerText")}</p>
      </div>
    </div>
  );
};

export default PendingAnalysisPage;
