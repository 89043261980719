import React, { useEffect, useState, useRef } from "react";
import Navbar from "./component/Navbar";
import "keen-slider/keen-slider.min.css"; // Import Keen Slider CSS
import { useKeenSlider } from "keen-slider/react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import "./App.css";
import ReactPixel from "react-facebook-pixel";

const App = () => {
  const [navbarVisible, setNavbarVisible] = useState(true);
  const [showBannerContent, setShowBannerContent] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDataBuyModalOpen, setIsDataBuyModalOpen] = useState(false);
  const [isDataBuyModalOpen15, setIsDataBuyModalOpen15] = useState(false);
  const [isModalChatRoomOpen, setIsModalChatRoomOpen] = useState(false);
  const [isChatRoomBuyModalOpen, setIsChatRoomBuyModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  const [termAndPolicyOpen, setTermAndPolicyOpen] = useState(false);
  const [termOpen, setTermOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { t, i18n } = useTranslation();
  const [closeDropdown, setCloseDropdown] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleModalChatRoom = () => {
    setIsModalChatRoomOpen(!isModalChatRoomOpen);
  };

  const toggleModalDataBuy = () => {
    setIsDataBuyModalOpen(!isDataBuyModalOpen);
  };
  const toggleModalDataBuy15 = () => {
    setIsDataBuyModalOpen15(!isDataBuyModalOpen15);
  };

  const toggleModalChatRoomBuy = () => {
    setIsChatRoomBuyModalOpen(!isChatRoomBuyModalOpen);
  };

  const toggleQuestionModal = () => {
    setIsQuestionModalOpen(!isQuestionModalOpen);
  };

  const navigate = useNavigate();

  const handleBuyNowData = (type) => {
    // Replace this with your product details
    let productDetails;
    if (type === 0) {
      productDetails = {
        type: 0,
        productName: "data_5.title",
        quantity: 1,
        price: "218.00",
      };
    } else if (type === 1) {
      productDetails = {
        type: 1,
        productName: "data_10.title",
        quantity: 1,
        price: "388.00",
      };
    } else {
      productDetails = {
        type: 2,
        productName: "All Symbol Chatroom",
        quantity: 1,
        price: "168.00",
      };
    }
    navigate("/checkout", { state: { productDetails } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setLoading(true);

    // Get the form data  const userEmail = formData.get("email");

    const formData = new FormData(e.target);
    const email = formData.get("email"); // Get the "email" field from FormData
    const feedback = formData.get("feedback"); // Get the "feedback" field from FormData
    try {
      // Send a POST request to your server's /send-email endpoint
      const response = await fetch("https://8bot-api.vercel.app/send-email", {
        method: "POST",
        body: JSON.stringify({ email, feedback }), // Send data as JSON
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
        },
      });

      if (response.ok) {
        // Email sent successfully, show a success message
        setSuccessMessage(t("successMessage"));
        setErrorMessage("");
        setTimeout(() => {
          setSuccessMessage("");
          setErrorMessage("");
        }, 3000);
      } else {
        // Email sending failed, show an error message
        setErrorMessage(t("errorMessage"));
        setSuccessMessage("");
      }
    } catch (error) {
      // Handle any network or server errors
      console.error("Error:", error);
      setErrorMessage(t("networkErrorMessage"));
      setSuccessMessage("");
    }
    setLoading(false);
    e.target.reset();
  };

  const toggleTermAndPolicyModal = () => {
    setTermAndPolicyOpen(!termAndPolicyOpen);
  };

  const toggleTermModal = () => {
    setTermOpen(!termOpen);
  };
  function Arrow(props) {
    return (
      <svg
        onClick={props.onClick}
        className={`arrow ${
          props.left ? "arrow--left ml-20" : "arrow--right mr-20"
        }`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    );
  }

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      // Set a default language if none is stored
      i18n.changeLanguage("en");
    }
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      // Set the navbar to not visible when the page is scrolled down
      const currentScrollY = window.scrollY;

      // Set navbar to not visible when scrolling down
      if (currentScrollY > lastScrollY) {
        setNavbarVisible(false);
        setCloseDropdown(!closeDropdown);
      } else {
        setNavbarVisible(true);
        setCloseDropdown(!closeDropdown);
      }

      // Update lastScrollY to current scroll position
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const id = entry.target.getAttribute("id");
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
          } else {
            entry.target.classList.remove("fade-in");
          }
        });
      },
      { rootMargin: "0px", threshold: 0.1 }
    );
    const hasIntroHash = window.location.hash === "#intro";

    if (hasIntroHash) {
      const introSection = document.getElementById("intro");
      if (introSection) {
        introSection.scrollIntoView({ behavior: "smooth" });
      }
    }
    ReactPixel.track("PageView");

    // Observe all sections
    document.querySelectorAll("section").forEach((section) => {
      observer.observe(section);
    });
    return () => {
      window.removeEventListener("scroll", handleScroll);
      observer.disconnect();
    };
  }, [navbarVisible]);

  return (
    <div className="text-white font-sans bg-[#0F1C2D]">
      {/* Navbar */}
      <Helmet>
        <title>Home - 8bot.tech</title>
        <link rel="canonical" href="https://8bot.tech/" />
        {/* <meta property="og:url" content="https://8bot.tech" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="8bot" />
        <meta property="og:description" content="We are 8bot, a company dedicated to providing high-quality data analysis services. With our AI technology, we are committed to helping clients gain profound insights from data to support their business decisions and growth strategies." />
        <meta property="og:image" content="https://8bot.tech/cover_trading.webp" /> */}
      </Helmet>
      <Navbar isVisible={navbarVisible} isCloseDropdown={closeDropdown} />

      {/* Banner Section */}
      <section className="relative text-center h-screen ">
        {/* Background Image */}
        <img
          src="banner.webp"
          className="absolute top-0 left-0 w-full h-screen object-cover"
          alt="Banner"
        />

        {/* Text Content */}
        <div className="absolute top-1/2 left-0 transform -translate-y-1/2 px-4 py-10 z-10 bg-[#0F1C2D] bg-opacity-50 w-1/2">
          <h1 className="text-[#E5F4FF] text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold leading-tight text-left">
            {t("welcome")}
          </h1>
          <p className="text-[#CADCE6] text-base sm:text-lg mt-4 text-left">
            {t("banner_content")}{" "}
            <span className="text-xl font-semibold">8bot </span>
            {t("banner_content1")}{" "}
          </p>
        </div>
      </section>

      {/* Service Section */}
      <section className="pt-24" id="space-section"></section>
      <section id="service-data" className=" px-5 items-center text-center">
        {/* Description */}

        <h2 className="text-[#E5F4FF] text-2xl sm:text-3xl md:text-4xl font-bold">
          {t("dataServices.title")}
        </h2>

        <h2 className="text-[#E5F4FF] text-2xl font-bold mt-8 custom-underline">
          {t("dataServices.analysis_title")}
        </h2>
        <p className="text-[#CADCE6] text-base sm:text-lg mt-1">
          {t("dataServices.analysis")}
        </p>

        <h2 className="text-[#E5F4FF] text-2xl font-bold mt-4  custom-underline">
          {t("dataServices.cleaning_title")}
        </h2>
        <p className="text-[#CADCE6] text-base sm:text-lg mt-1">
          {t("dataServices.cleaning")}
        </p>

        <h2 className="text-[#E5F4FF] text-2xl font-bold mt-4  custom-underline">
          {t("dataServices.prediction_title")}
        </h2>
        <p className="text-[#CADCE6] text-base sm:text-lg mt-1">
          {t("dataServices.prediction")}
        </p>

        <h2 className="text-[#E5F4FF] text-2xl font-bold mt-4  custom-underline">
          {t("dataServices.visualization_title")}
        </h2>
        <p className="text-[#CADCE6] text-base sm:text-lg mt-1">
          {t("dataServices.visualization")}
        </p>

        <h2 className="text-[#E5F4FF] text-2xl font-bold mt-4  custom-underline">
          {t("dataServices.collection_title")}
        </h2>
        <p className="text-[#CADCE6] text-base sm:text-lg mt-1 mb-8">
          {t("dataServices.collection")}
        </p>

        <small className="text-[#F7931E] font-bold  italic">
          {t("dataServices.collection_warning")}
        </small>
      </section>
      {/* Intro Section */}
      <section className="pt-24" id="space-section">
        {" "}
      </section>
      <section
        id="intro"
        className="px-5 flex flex-col lg:flex-row items-center"
      >
        {/* Product Image */}
        <div className="flex-1">
          <img
            src="data_analysis.png"
            alt="Visualize Trading History"
            className="w-full h-auto"
          />
        </div>

        {/* Description */}
        <div className="flex-1 mt-6 lg:mt-0 lg:ml-8">
          <h2 className="text-[#E5F4FF] text-2xl sm:text-3xl md:text-4xl font-bold">
            {t("first_section_title")}{" "}
          </h2>
          <p className="text-[#CADCE6] text-base sm:text-lg mt-4">
            {t("first_section_content")}{" "}
            <span className="text-xl font-semibold">8bot</span>{" "}
            {t("first_section_content1")}{" "}
            <span className="text-xl font-semibold">8bot</span>{" "}
            {t("first_section_content2")}
          </p>

          <div className="flex mt-6">
            <button
              type="submit"
              className="w-1/3 flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 "
              onClick={toggleModalDataBuy}
            >
              {t("buy_btn")} 5
            </button>

            <button
              type="submit"
              className="w-1/3 flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 ml-4"
              onClick={toggleModalDataBuy15}
            >
              {t("buy_btn")} 10
            </button>
          </div>
        </div>
      </section>

      {isDataBuyModalOpen && (
        <div
          className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center"
          onClick={toggleModalDataBuy}
        >
          <div className="bg-[#0F1C2D] text-white p-4 md:p-8 rounded-lg flex flex-col md:flex-row gap-4 md:gap-8 max-w-full md:max-w-4xl lg:mx-auto md:mx-10 mx-5">
            {/* Left Section */}
            <div className="flex flex-col justify-between w-full">
              <div className="pb-4">
                <h1 className="text-[#E5F4FF] text-2xl md:text-3xl mb-4 font-Roboto">
                  {t("data_5.title")}
                </h1>
                <ul className="text-[#CADCE6] list-disc list-inside">
                  {t("data_5.content", { returnObjects: true }).map(
                    (question, index) => (
                      <li key={index}>{question}</li>
                    )
                  )}
                </ul>
              </div>
              <div>
                <small className="text-[#F7931E] font-bold italic mt-4">
                  {t("customizePackage")}
                </small>
                <p className="text-[#F7931E] font-bold mt-4">
                  {t("price")}: RM218 ({t("limited_offer")})
                </p>
                <button
                  className="mt-4 bg-[#1DBF73] text-white py-2 px-4 rounded w-full focus:outline-none"
                  onClick={() => handleBuyNowData(0)}
                >
                  {t("buy_btn")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {isDataBuyModalOpen15 && (
        <div
          className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center"
          onClick={toggleModalDataBuy15}
        >
          <div className="bg-[#0F1C2D] text-white p-4 md:p-8 rounded-lg flex flex-col md:flex-row gap-4 md:gap-8 max-w-full md:max-w-4xl lg:mx-auto md:mx-10 mx-5">
            {/* Right Section */}
            <div className="flex flex-col justify-between w-full">
              <div className="pb-4">
                <h1 className="text-[#E5F4FF] text-2xl md:text-3xl mb-4 font-Roboto">
                  {t("data_10.title")}
                </h1>

                <ul className="text-[#CADCE6] list-disc list-inside">
                  {t("data_10.content", { returnObjects: true }).map(
                    (question, index) => (
                      <li key={index}>{question}</li>
                    )
                  )}
                </ul>
              </div>
              <div>
                <small className="text-[#F7931E] font-bold mt-4 italic">
                  {t("customizePackage")}
                </small>
                <p className="text-[#F7931E] font-bold mt-4">
                  {t("price")}: RM388 ({t("limited_offer")})
                </p>
                <button
                  className="mt-4 bg-[#1DBF73] text-white py-2 px-4 rounded w-full focus:outline-none"
                  onClick={() => handleBuyNowData(1)}
                >
                  {t("buy_btn")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* How to use:provide data Section */}
      <section className="pt-24" id="space-section"></section>
      <section
        id="prodive-data"
        className=" px-5 flex flex-col-reverse lg:flex-row items-center"
      >
        {/* Description */}
        <div className="flex-1 mt-6 lg:mt-0 lg:mr-8">
          <h2 className="text-[#E5F4FF] text-2xl sm:text-3xl md:text-4xl font-bold">
            {t("second_section_title")}
          </h2>
          <p className="text-[#CADCE6] text-base sm:text-lg mt-4">
            {t("second_section_content")}
          </p>

          <div className="flex mt-6">
            <button
              type="submit"
              className="w-1/3 flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 "
              onClick={toggleModalDataBuy}
            >
              {t("buy_btn")} 5
            </button>

            <button
              type="submit"
              className="w-1/3 flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 ml-4"
              onClick={toggleModalDataBuy15}
            >
              {t("buy_btn")} 10
            </button>
          </div>
        </div>

        {/* Teaser Image */}
        <div className="flex-1">
          <img
            src="provide_data.webp"
            alt="Provide Data"
            className="w-full h-auto"
          />
        </div>
      </section>

      {isChatRoomBuyModalOpen && (
        <div
          className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center"
          onClick={toggleModalChatRoomBuy}
        >
          <div className="bg-[#0F1C2D] text-white p-4 md:p-8 rounded-lg flex flex-col md:flex-row gap-4 md:gap-8 max-w-full md:max-w-4xl lg:mx-auto md:mx-10 mx-5">
            {/* Left Section */}
            <div className="flex flex-col">
              <div>
                <h1 className="text-[#E5F4FF] text-2xl md:text-3xl mb-4 font-['Roboto']">
                  All Symbol Discussion Chatroom
                </h1>
                <ul className="text-[#CADCE6] list-disc list-inside">
                  <li>One-time payment for a lifetime.</li>
                  <li>Discuss any symbol.</li>
                  <li>have more companions to trade with you.</li>
                </ul>
              </div>
              <div>
                <p className="text-[#F7931E] font-bold mt-4">
                  Price: RM168 (Limited Time Offer)
                </p>
                <button
                  className="mt-4 bg-[#1DBF73] text-white py-2 px-4 rounded w-1/2"
                  onClick={() => handleBuyNowData(2)}
                >
                  Buy
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* How to use:provide question Section */}
      <section className="pt-24" id="space-section"></section>
      <section
        id="provide-question"
        className=" px-5 flex flex-col lg:flex-row items-center"
      >
        {/* Teaser Image */}
        <div className="flex-1">
          <img
            src="provide_questions.webp"
            alt="Provide Question"
            className="w-full h-auto"
          />
        </div>
        {/* Description */}
        <div className="flex-1 mt-6 lg:mt-0 lg:ml-8">
          <h2 className="text-[#E5F4FF] text-2xl sm:text-3xl md:text-4xl font-bold">
            {t("third_section_title")}
          </h2>
          <p className="text-[#CADCE6] text-base sm:text-lg mt-4">
            {t("third_section_content")}{" "}
            <span className="text-xl font-semibold">8bot</span>{" "}
            {t("third_section_content1")}
          </p>

          <div className="flex mt-6">
            <button
              type="submit"
              className="w-1/3 flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 focus:outline-none"
              onClick={toggleQuestionModal}
            >
              {t("explore_btn")}
            </button>
          </div>
        </div>
      </section>

      {isQuestionModalOpen && (
        <div className="overlay" onClick={toggleQuestionModal}>
          <div className="modal mx-4">
            {/* Left Section */}

            <h1 className="text-[#E5F4FF] text-2xl md:text-3xl mb-4 font-['Roboto']">
              {t("referenceQuestions.title")}
            </h1>
            <ul className="text-[#CADCE6] list-disc list-inside">
              {t("referenceQuestions.questions", { returnObjects: true }).map(
                (question, index) => (
                  <li key={index}>{question}</li>
                )
              )}
            </ul>
          </div>
          <div></div>
        </div>
      )}

      {/* Waiting Result Section */}
      <section className="pt-24" id="space-section"></section>
      <section
        id="waiting-result"
        className="px-5 flex flex-col-reverse lg:flex-row items-center"
      >
        {/* Description */}
        <div className="flex-1 mt-6 lg:mt-0 lg:mr-8">
          <h2 className="text-[#E5F4FF] text-2xl sm:text-3xl md:text-4xl font-bold">
            {t("four_section_title")}{" "}
          </h2>
          <p className="text-[#CADCE6] text-base sm:text-lg mt-4">
            {t("four_section_content")}{" "}
            <span className="text-xl font-semibold">8bot</span>{" "}
            {t("four_section_content1")}{" "}
          </p>

          <div className="flex mt-6">
            <button
              type="submit"
              className="w-1/3 flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 "
              onClick={toggleModalDataBuy}
            >
              {t("buy_btn")} 5
            </button>

            <button
              type="submit"
              className="w-1/3 flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1DBF73] hover:bg-green-700 ml-4"
              onClick={toggleModalDataBuy15}
            >
              {t("buy_btn")} 10
            </button>
          </div>
        </div>

        {/* Teaser Image */}
        <div className="flex-1">
          <img
            src="awaiting_results.webp"
            alt="Await Result"
            className="w-full h-auto"
          />
        </div>
      </section>

      {/* Contact Us Section */}
      <section className="pt-24" id="space-section"></section>
      <section
        id="contact-us"
        className="bg-[#0F1C2D] text-white px-5 text-center"
      >
        <div className="max-w-xl mx-auto">
          <h2 className="text-[#E5F4FF] text-3xl sm:text-4xl font-bold text-center mb-6">
            {t("contact_us_title")}
          </h2>

          <small className="text-[#F7931E] font-bold mt-4 italic">
            {t("contactUsContent")}
          </small>
          <div className="flex justify-center space-x-6 mt-8">
            <a
              href="https://www.facebook.com/profile.php?id=61557074626805"
              className="text-white"
            >
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a href="https://www.instagram.com/8bot___/" className="text-white">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            {/* <a href="https://wa.me/60177396184" className="text-white">
              <FontAwesomeIcon icon={faWhatsapp} size="2x" />
            </a> */}
          </div>
          {/* <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-[#CADCE6]"
              >
                {t("contact_email_address")}
              </label>
              <input
                type="email"
                id="email"
                name="email" // Add the name attribute here
                className="mt-1 block w-full px-3 py-2 bg-[#0F1C2D] border border-[#CADCE6] rounded-md text-base text-[#CADCE6] focus:outline-none focus:border-[#1DBF73] h-12"
                placeholder="Enter your email"
                required
              />
            </div>
            <div>
              <label
                htmlFor="feedback"
                className="block text-sm font-medium text-[#CADCE6]"
              >
                {t("contact_feedback")}
              </label>
              <textarea
                id="feedback"
                name="feedback" // Add the name attribute here
                rows={4}
                className="mt-1 block w-full px-3 py-2 bg-[#0F1C2D] border border-[#CADCE6] rounded-md text-base text-[#CADCE6] focus:outline-none focus:border-[#1DBF73]"
                placeholder="Your feedback"
                required
              ></textarea>
            </div>
            <div>
              <button
                type="submit"
                className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none ${
                  loading
                    ? "bg-[#1DBF73] cursor-not-allowed"
                    : "bg-[#1DBF73] hover:bg-green-700"
                }`}
                disabled={loading}
              >
                {loading ? t("processing") : t("sendMessage")}
              </button>
            </div>

            {successMessage && (
              <div className="text-[#1DBF73]">{successMessage}</div>
            )}
            {errorMessage && <div className="text-red-600">{errorMessage}</div>}
            <p className="text-[#CADCE6] text-base sm:text-lg">
              {t("contact_prompt")}
            </p>
          </form> */}
        </div>
      </section>

      {/* Footer */}

      <footer className="text-center p-8">
        <p className="text-[#CADCE6] font-bold text-sm sm:text-base mt-4">
          {t("openingHour")}
        </p>
        <p className="text-[#CADCE6] text-xs sm:text-sm mt-4">
          © 2024 8bot. {t("footer")}
        </p>
        <p className="mt-2">
          <a href="#" className="text-[#CADCE6] mx-2">
            <FontAwesomeIcon icon={faFacebook} className="w-6 h-6" />
          </a>
          <a href="#" className="text-[#CADCE6] mx-2">
            <FontAwesomeIcon icon={faInstagram} className="w-6 h-6" />
          </a>
        </p>
        <a href="mailto:eightbot88@hotmail.com" className="text-[#CADCE6] mt-2">
          eightbot88@hotmail.com
        </a>

        <div className="text-[#CADCE6] text-xs sm:text-sm cursor-pointer mt-4">
          <span className="underline" onClick={toggleTermAndPolicyModal}>
            {t("privacy")}
          </span>{" "}
          {t("and")}{" "}
          <span className="underline" onClick={toggleTermModal}>
            {t("term")}
          </span>
        </div>
      </footer>
      {termAndPolicyOpen && (
        <div className="overlay" onClick={toggleTermAndPolicyModal}>
          <div className="modal mx-4">
            {/* Left Section */}

            <h1 className="text-2xl font-bold mb-4">
              {t("privacyPolicy.title")}
            </h1>

            <p className="mb-4">
              1. {t("privacyPolicy.title_1")}
              <br />
              {t("privacyPolicy.content_1")}
            </p>
            <p className="mb-4">
              2. {t("privacyPolicy.title_2")}
              <br />
              {t("privacyPolicy.content_2")}
            </p>
            <p className="mb-4">
              3. {t("privacyPolicy.title_3")}
              <br />
              {t("privacyPolicy.content_3")}
            </p>
            <p className="mb-4">
              4. {t("privacyPolicy.title_4")}
              <br />
              {t("privacyPolicy.content_4")}
            </p>
            <p className="mb-4">
              5. {t("privacyPolicy.title_5")}
              <br />
              {t("privacyPolicy.content_5")}
            </p>
          </div>
          <div></div>
        </div>
      )}

      {termOpen && (
        <div className="overlay" onClick={toggleTermModal}>
          <div className="modal mx-4">
            {/* Left Section */}
            <h1 className="text-2xl font-bold mb-4">
              1. {t("termsOfService.1.title")}
            </h1>

            <p className="mb-4">
              1.1 {t("termsOfService.1.1.1")}
              <br /> <br />
              1.2 {t("termsOfService.1.1.2")}
              <br /> <br />
              1.3 {t("termsOfService.1.1.3")}
              <br /> <br />
              1.4 {t("termsOfService.1.1.4")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              2. {t("termsOfService.2.title")}
            </h1>

            <p className="mb-4">
              2.1 {t("termsOfService.2.2.1")}
              <br /> <br />
              2.2 {t("termsOfService.2.2.2")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              3. {t("termsOfService.3.title")}
            </h1>

            <p className="mb-4">
              3.1 {t("termsOfService.3.3.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              4. {t("termsOfService.4.title")}
            </h1>

            <p className="mb-4">
              4.1 {t("termsOfService.4.4.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              5. {t("termsOfService.5.title")}
            </h1>

            <p className="mb-4">
              5.1 {t("termsOfService.5.5.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              6. {t("termsOfService.6.title")}
            </h1>

            <p className="mb-4">
              6.1 {t("termsOfService.6.6.1")}
              <br /> <br />
            </p>

            <h1 className="text-2xl font-bold mb-4">
              7. {t("termsOfService.7.title")}
            </h1>

            <p className="mb-4">
              7.1 {t("termsOfService.7.7.1")}
              <br /> <br />
              7.2 {t("termsOfService.7.7.2")}
            </p>
          </div>
          <div></div>
        </div>
      )}
    </div>
  );
};

export default App;
