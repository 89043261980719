import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.jsx";
import { createRoot } from "react-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import HighChartHistory from "./component/HighChartHistory.jsx";
import ImageUpload from "./component/ImageUpload.jsx";
import LogIn from "./component/auth/Login.jsx";
import { AuthContextProvider } from "./context/AuthContext.js";
import Dashboard from "./component/Dashboard.jsx";
import CheckoutPage from "./component/checkout/CheckoutPage.jsx";
import ThankYouPage from "./component/checkout/ThankYouPage.jsx";
import CheckoutFPX from "./component/checkout/CheckoutFPX.jsx";
import DashboardNew from "./component/DashboardNew.jsx";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import ProjectShowcasePage from "./component/ProjectShowCase.jsx";
import FinancialSubpage from "./component/showcases/FinancialSubPage.jsx";
import ResultPage from "./component/dashboard/result.jsx";
import UploadDataAnalysis from "./component/dashboard/uploadDataAnalysis.jsx";
import PendingAnalysisPage from "./component/dashboard/pendingDataAnalysis.jsx";
import TradingSubPage from "./component/showcases/TradingSubPage.jsx";
import ReactPixel from "react-facebook-pixel";

// Assuming your 'root' element exists in your HTML file
const rootElement = document.getElementById("root");

ReactPixel.init("748496853998297");

// Render the App component directly
createRoot(rootElement).render(
  <Router>
    <AuthContextProvider>
      <I18nextProvider i18n={i18n}>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/uploadimage" element={<ImageUpload />} />
          <Route path="/auth/login" element={<LogIn />} />
          <Route path="/home" element={<DashboardNew />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/thankyou" element={<ThankYouPage />} />
          <Route path="/projectShowCases" element={<ProjectShowcasePage />} />
          <Route path="/trading/:id" element={<TradingSubPage />} />
          {/* <Route path="/result" element={<ResultPage />} /> */}
          <Route
            path="/analysis/:email/:index/:username/:displayuseremail"
            element={<ResultPage />}
          />
          <Route
            path="/uploadAnalysis/:email/:username/:displayuseremail"
            element={<UploadDataAnalysis />}
          />
          <Route
            path="/pendingAnalysis/:email/:username/:displayuseremail"
            element={<PendingAnalysisPage />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </I18nextProvider>
    </AuthContextProvider>
  </Router>
);
