import React, { createContext } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();

// Create Auth Context for login state management
export const AuthContext = createContext(null);
const initialState = {
  isAuthenticated: false,
  user: {},
  token: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.clear();
      const expires = new Date();
      expires.setDate(expires.getDate() + 30); // Set expiration to 30 days from now
      
      cookies.set("token",  action.payload.token, { path: "/", expires });

      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
      return {
        ...state,
        user: action.payload.me,
      };
    case "LOGOUT":
      localStorage.clear();
      cookies.remove("token");
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        user: null,
      };
    default:
      return state;
  }
};

// Create a provider for components to consume and subscribe to changes
export const AuthContextProvider = (props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {props.children}
    </AuthContext.Provider>
  );
};
